import { formatISO } from 'date-fns'
import { z } from 'zod'
import { TimeRange } from 'src/types'
import apiFetch from './apiFetch'
import { ForecastDto, ForecastValueDto } from './types'

export interface FetchForecast {
  modelId: string
  factory: string
}

export async function fetchForecast({
  modelId,
  factory,
}: FetchForecast): Promise<ForecastDto> {
  const response = await apiFetch({
    path: `/forecast/model/${modelId}`,
    query: {
      factory,
    },
  })
  return ForecastDto.parse(await response.json())
}

export interface FetchHistoricalForecast {
  id: string
  offset: number
  timeRange: TimeRange
  factory: string
}

export async function fetchHistoricalForecast({
  id,
  offset,
  timeRange: { from, to },
  factory,
}: FetchHistoricalForecast): Promise<ForecastValueDto[]> {
  const response = await apiFetch({
    path: `/forecast/model/${id}/historical`,
    query: {
      offset,
      from: formatISO(from),
      to: formatISO(to),
      factory,
    },
  })
  return z.array(ForecastValueDto).parse(await response.json())
}
