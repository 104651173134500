import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { ErrorDisplay } from 'pages/app'
import { isDefined } from 'src/types'
import { useNavigationContext } from 'src/contexts/navigation'
import { DynamicTable, Icon, Spinner, Text } from 'src/components/ui'
import {
  useOpcConnection,
  useOpcSubscription,
  useOpcSubscriptionNodes,
} from '../opc-ua-connection.api'
import { getOpcSubscriptionTableConfig } from './opc-ua-subs.config'
import { OpcUaSubscriptionNavigation } from './OpcUaSubsriptionNavigation'

export function OpcUaSubscriptionPage(): JSX.Element {
  const { orgId, siteId, gatewayId, connectionId, subscriptionId } = useParams()
  if (!orgId || !siteId || !gatewayId || !connectionId || !subscriptionId)
    throw new Error('siteId, gatewayId or subscriptionId is not defined')
  const connectionQuery = useOpcConnection(siteId, gatewayId, connectionId)
  const subscriptionQuery = useOpcSubscription(subscriptionId)
  const nodesQuery = useOpcSubscriptionNodes(subscriptionId)

  // Fetch all pages of nodes
  useEffect(() => {
    if (nodesQuery.hasNextPage) {
      nodesQuery.fetchNextPage()
    }
  }, [nodesQuery])

  const { setTitleComponent } = useNavigationContext()
  useEffect(() => {
    setTitleComponent(
      <OpcUaSubscriptionNavigation
        orgId={orgId}
        siteId={siteId}
        gatewayId={gatewayId}
        opcConnectionId={connectionId}
        subscriptionId={subscriptionId}
      />,
    )
    return () => setTitleComponent(null)
  }, [
    connectionId,
    gatewayId,
    orgId,
    siteId,
    subscriptionId,
    setTitleComponent,
  ])

  if (subscriptionQuery.isLoading || connectionQuery.isLoading)
    return <Spinner />
  if (subscriptionQuery.isError || connectionQuery.isError) {
    const errorQuery = subscriptionQuery.isError
      ? subscriptionQuery
      : connectionQuery
    return (
      <ErrorDisplay
        error={errorQuery.error}
        message="Something went wrong"
        action={errorQuery.refetch}
      />
    )
  }

  // Filter out nodes that are not defined
  // and map them to the format required by the table
  const nodes = nodesQuery.data?.pages
    .map(p => p.items)
    .flat()
    .filter(isDefined)
    .map(n => ({
      id: n.nodeId,
      displayName: n.nodeId,
    }))

  return (
    <div className="h-full p-s">
      <div className="flex h-full flex-col rounded-2xs bg-white px-s py-m">
        <div className="flex items-center gap-xl">
          <Icon
            icon={light('cloud-arrow-up')}
            className="-mr-m size-[40px] text-grey-text"
          />
          <div>
            <Text variant="description">Subscription ID</Text>
            <Text bold>{subscriptionQuery.data.id}</Text>
          </div>
          <div>
            <Text variant="description">Server Endpoint</Text>
            <Text bold>{connectionQuery.data.endpoint}</Text>
          </div>
        </div>
        <div className="flex flex-1 flex-col">
          <div className="grid h-full pt-m">
            {nodesQuery.isLoading || nodesQuery.isFetching ? (
              <Spinner />
            ) : (
              <DynamicTable
                id="OpcSubscription"
                config={getOpcSubscriptionTableConfig({ siteId })}
                data={nodes ?? []}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
