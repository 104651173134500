import { GraphQLClient, RequestOptions } from 'graphql-request'
import { gql } from 'graphql-request'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders']
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  DateTime: { input: string; output: string }
  Long: { input: number; output: number }
  Milliseconds: { input: number; output: number }
  Period: { input: string; output: string }
  UInt: { input: number; output: number }
  ULong: { input: number; output: number }
  Uri: { input: string; output: string }
}

export type GqlAcceptFactoryInvitationInput = {
  invitationId: Scalars['ID']['input']
}

export type GqlAcceptFactoryInvitationPayload = {
  __typename?: 'AcceptFactoryInvitationPayload'
  invitation: GqlFactoryInvitation
}

export type GqlActivateModelInput = {
  id: Scalars['ID']['input']
}

export type GqlActivateModelPayload = {
  __typename?: 'ActivateModelPayload'
  model: GqlModel
}

export type GqlActiveModelCreditsUsage = {
  __typename?: 'ActiveModelCreditsUsage'
  batch: GqlActiveModelTypeCreditsUsage
  streaming: GqlActiveModelTypeCreditsUsage
  total: Scalars['Int']['output']
}

export type GqlActiveModelType = 'BATCH' | 'STREAMING'

export type GqlActiveModelTypeCreditsUsage = {
  __typename?: 'ActiveModelTypeCreditsUsage'
  creditsPerModel: Scalars['Int']['output']
  models: Scalars['Int']['output']
  total: Scalars['Int']['output']
  type: GqlActiveModelType
}

export type GqlAddAnomalyCommentInput = {
  anomalyId: Scalars['ID']['input']
  comment: Scalars['String']['input']
}

export type GqlAddAnomalyCommentPayload = {
  __typename?: 'AddAnomalyCommentPayload'
  comment: GqlAnomalyComment
}

export type GqlAddInputTagsToModelInput = {
  id: Scalars['ID']['input']
  tagIds: Array<Scalars['ID']['input']>
}

export type GqlAddInputTagsToModelPayload = {
  __typename?: 'AddInputTagsToModelPayload'
  model: GqlModel
}

export type GqlAddStarInput = {
  starrableId: Scalars['ID']['input']
}

export type GqlAddStarPayload = {
  __typename?: 'AddStarPayload'
  starrable?: Maybe<GqlStarrable>
}

export type GqlAddUserInput = {
  customerId: Scalars['ID']['input']
  email: Scalars['String']['input']
  factoryRoles: Array<GqlFactoryRoleInput>
  name: Scalars['String']['input']
  role?: GqlCustomerRole
}

export type GqlAddUserPayload = {
  __typename?: 'AddUserPayload'
  user: GqlUser
}

export type GqlAgent = GqlNode & {
  __typename?: 'Agent'
  createdOn: Scalars['DateTime']['output']
  hostname?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastPing?: Maybe<Scalars['DateTime']['output']>
  listCertificates: GqlAgentCertificatesResponse
  opcConnections?: Maybe<GqlOpcConnectionConnection>
  opcStreams?: Maybe<GqlOpcStreamConnection>
  ping: GqlAgentResponse
  serverEndpoints: GqlAgentOpcEndpointDescriptionsResponse
  startTime?: Maybe<Scalars['DateTime']['output']>
  validateOpcConnection: GqlValidateOpcConnectionResponse
  version?: Maybe<Scalars['String']['output']>
}

export type GqlAgentListCertificatesArgs = {
  timeout?: Scalars['Milliseconds']['input']
}

export type GqlAgentOpcConnectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
}

export type GqlAgentOpcStreamsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
}

export type GqlAgentPingArgs = {
  timeout?: Scalars['Milliseconds']['input']
}

export type GqlAgentServerEndpointsArgs = {
  timeout?: Scalars['Milliseconds']['input']
  url: Scalars['String']['input']
}

export type GqlAgentValidateOpcConnectionArgs = {
  connection: GqlValidateOpcConnectionInput
  timeout?: Scalars['Milliseconds']['input']
}

export type GqlAgentCertificate = {
  __typename?: 'AgentCertificate'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  expiresAt?: Maybe<Scalars['DateTime']['output']>
  path: Scalars['String']['output']
  serialNumber: Scalars['String']['output']
  thumbprint: Scalars['String']['output']
}

export type GqlAgentCertificatesResponse = {
  __typename?: 'AgentCertificatesResponse'
  certificates?: Maybe<Array<GqlAgentCertificate>>
  error?: Maybe<Scalars['String']['output']>
}

export type GqlAgentConnection = {
  __typename?: 'AgentConnection'
  edges?: Maybe<Array<Maybe<GqlAgentEdge>>>
  items?: Maybe<Array<Maybe<GqlAgent>>>
  pageInfo: GqlPageInfo
  totalCount?: Maybe<Scalars['Int']['output']>
}

export type GqlAgentEdge = {
  __typename?: 'AgentEdge'
  cursor: Scalars['String']['output']
  node?: Maybe<GqlAgent>
}

export type GqlAgentOpcEndpointDescriptionsResponse = {
  __typename?: 'AgentOpcEndpointDescriptionsResponse'
  endpoints?: Maybe<Array<GqlOpcEndpointDescription>>
  error?: Maybe<Scalars['String']['output']>
}

export type GqlAgentResponse = {
  __typename?: 'AgentResponse'
  error?: Maybe<Scalars['String']['output']>
}

export type GqlAnomaly = GqlNode & {
  __typename?: 'Anomaly'
  comments: Array<GqlAnomalyComment>
  end?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  inProgress: Scalars['Boolean']['output']
  model: GqlAnomalyModel
  next?: Maybe<GqlAnomaly>
  previous?: Maybe<GqlAnomaly>
  restId: Scalars['Int']['output']
  score?: Maybe<Scalars['Float']['output']>
  start: Scalars['DateTime']['output']
  state: GqlAnomalyState
  type: GqlAnomalyType
}

export type GqlAnomalyComment = GqlNode & {
  __typename?: 'AnomalyComment'
  anomaly: GqlAnomaly
  at: Scalars['DateTime']['output']
  by: Scalars['String']['output']
  comment: Scalars['String']['output']
  id: Scalars['ID']['output']
}

export type GqlAnomalyCondition = {
  __typename?: 'AnomalyCondition'
  errorScore: GqlErrorScore
  threshold: Scalars['Float']['output']
  thresholdKind: GqlThresholdKind
}

export type GqlAnomalyConditionInput = {
  errorScoreKind: GqlErrorScoreKind
  threshold: Scalars['Float']['input']
  thresholdKind: GqlThresholdKind
  transform: GqlTransform
}

export type GqlAnomalyConnection = {
  __typename?: 'AnomalyConnection'
  edges?: Maybe<Array<Maybe<GqlAnomalyEdge>>>
  items?: Maybe<Array<Maybe<GqlAnomaly>>>
  pageInfo: GqlPageInfo
  totalCount?: Maybe<Scalars['Int']['output']>
}

export type GqlAnomalyEdge = {
  __typename?: 'AnomalyEdge'
  cursor: Scalars['String']['output']
  node?: Maybe<GqlAnomaly>
}

export type GqlAnomalyModel = GqlModel &
  GqlNode &
  GqlStarrable & {
    __typename?: 'AnomalyModel'
    activated?: Maybe<Scalars['DateTime']['output']>
    activeTrainedModel?: Maybe<GqlTrainedModel>
    anomalies: Array<GqlAnomaly>
    anomalyConditions: Array<GqlAnomalyCondition>
    anomalyGenerationFilter?: Maybe<Scalars['String']['output']>
    availableErrorScores: Array<GqlErrorScore>
    created: Scalars['DateTime']['output']
    createdBy?: Maybe<GqlUser>
    description: Scalars['String']['output']
    errorMessage?: Maybe<Scalars['String']['output']>
    errorScore: GqlTimeSeries
    errorScore2: GqlTimeSeries
    id: Scalars['ID']['output']
    initialInferenceEnd?: Maybe<Scalars['DateTime']['output']>
    initialInferenceStart?: Maybe<Scalars['DateTime']['output']>
    inputTags: Array<GqlTag>
    lastStateChange: Scalars['DateTime']['output']
    latestAnomaly?: Maybe<GqlAnomaly>
    method: GqlModelMethod
    methodId: Scalars['ID']['output']
    name: Scalars['String']['output']
    prediction: GqlTimeSeries
    shortAnomalyThreshold?: Maybe<Scalars['Float']['output']>
    spikeAnomalyThreshold?: Maybe<Scalars['Float']['output']>
    state: GqlModelState
    tag: GqlTag
    tagId: Scalars['ID']['output']
    trainedModels: Array<GqlTrainedModel>
    trainingEnd?: Maybe<Scalars['DateTime']['output']>
    trainingPeriod?: Maybe<Scalars['Period']['output']>
    trainingStart?: Maybe<Scalars['DateTime']['output']>
    type: GqlModelType
    typeId: Scalars['ID']['output']
    useRobustAnomalyScores?: Maybe<Scalars['Boolean']['output']>
    viewerHasStarred: Scalars['Boolean']['output']
  }

export type GqlAnomalyModelErrorScoreArgs = {
  anomalyType?: InputMaybe<GqlAnomalyType>
  from: Scalars['DateTime']['input']
  interval: Scalars['Int']['input']
  to: Scalars['DateTime']['input']
}

export type GqlAnomalyModelErrorScore2Args = {
  errorScoreKind: GqlErrorScoreKind
  from: Scalars['DateTime']['input']
  interval: Scalars['Int']['input']
  to: Scalars['DateTime']['input']
  transform: GqlTransform
}

export type GqlAnomalyModelPredictionArgs = {
  from: Scalars['DateTime']['input']
  interval: Scalars['Int']['input']
  to: Scalars['DateTime']['input']
}

export type GqlAnomalyState =
  | 'ANOMALY_KNOWN'
  | 'ANOMALY_UNKNOWN'
  | 'INCONCLUSIVE'
  | 'IN_PROGRESS'
  | 'NEW'
  | 'NORMAL_BEHAVIOUR'

export type GqlAnomalyStateCount = {
  __typename?: 'AnomalyStateCount'
  count: Scalars['Int']['output']
  state: GqlAnomalyState
}

export type GqlAnomalyType = 'SHORT' | 'SPIKE'

export type GqlArchiveModelInput = {
  id: Scalars['ID']['input']
}

export type GqlArchiveModelPayload = {
  __typename?: 'ArchiveModelPayload'
  model: GqlModel
}

export type GqlAsset = GqlNode & {
  __typename?: 'Asset'
  alias?: Maybe<Scalars['String']['output']>
  hierarchyAsset?: Maybe<GqlHierarchyAsset>
  id: Scalars['ID']['output']
  key: Scalars['Int']['output']
  name: Scalars['String']['output']
}

export type GqlAssetHierarchyAssetArgs = {
  view?: InputMaybe<GqlAssetViewType>
}

export type GqlAssetConnection = {
  __typename?: 'AssetConnection'
  edges?: Maybe<Array<Maybe<GqlAssetEdge>>>
  items?: Maybe<Array<Maybe<GqlAsset>>>
  pageInfo: GqlPageInfo
  totalCount?: Maybe<Scalars['Int']['output']>
}

export type GqlAssetEdge = {
  __typename?: 'AssetEdge'
  cursor: Scalars['String']['output']
  node?: Maybe<GqlAsset>
}

export type GqlAssetViewType = 'DEPLOYMENT' | 'MODEL'

export type GqlCancelFactoryInvitationInput = {
  invitationId: Scalars['ID']['input']
}

export type GqlCancelFactoryInvitationPayload = {
  __typename?: 'CancelFactoryInvitationPayload'
  invitation: GqlFactoryInvitation
}

export type GqlCreateAssetInput = {
  alias?: InputMaybe<Scalars['String']['input']>
  factoryId: Scalars['ID']['input']
  name: Scalars['String']['input']
}

export type GqlCreateAssetPayload = {
  __typename?: 'CreateAssetPayload'
  asset: GqlAsset
}

export type GqlCreateModelInput = {
  description?: InputMaybe<Scalars['String']['input']>
  factoryId: Scalars['ID']['input']
  inputTagIds: Array<Scalars['ID']['input']>
  methodId: Scalars['ID']['input']
  name: Scalars['String']['input']
  tagId: Scalars['ID']['input']
  trainingEnd?: InputMaybe<Scalars['DateTime']['input']>
  trainingPeriod?: InputMaybe<Scalars['Period']['input']>
  trainingStart?: InputMaybe<Scalars['DateTime']['input']>
  typeId: Scalars['ID']['input']
}

export type GqlCreateModelPayload = {
  __typename?: 'CreateModelPayload'
  model: GqlModel
}

export type GqlCreateOpcConnectionInput = {
  agentId: Scalars['ID']['input']
  credentials?: InputMaybe<GqlOpcCredentialsInput>
  endpoint: Scalars['Uri']['input']
  ignoreServerEndpoint: Scalars['Boolean']['input']
  name: Scalars['String']['input']
  securityMode?: InputMaybe<GqlOpcSecurityMode>
  securityPolicy?: InputMaybe<GqlOpcSecurityPolicy>
  x509Thumbprint?: InputMaybe<Scalars['String']['input']>
}

export type GqlCreateOpcConnectionPayload = {
  __typename?: 'CreateOpcConnectionPayload'
  connection: GqlOpcConnection
}

export type GqlCreateTrendViewInput = {
  data: Scalars['String']['input']
  factoryId: Scalars['ID']['input']
  name: Scalars['String']['input']
  shared: Scalars['Boolean']['input']
}

export type GqlCreateTrendViewPayload = {
  __typename?: 'CreateTrendViewPayload'
  trendView: GqlTrendView
}

export type GqlCustomer = GqlNode & {
  __typename?: 'Customer'
  factories?: Maybe<GqlFactoryConnection>
  factoryCount: Scalars['Int']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  outsideCollaborators?: Maybe<GqlUserConnection>
  userCount: Scalars['Int']['output']
  users?: Maybe<GqlCustomerUserConnection>
  viewerRole?: Maybe<GqlCustomerRole>
}

export type GqlCustomerFactoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
}

export type GqlCustomerOutsideCollaboratorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
}

export type GqlCustomerUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  status?: InputMaybe<GqlUserStatus>
}

export type GqlCustomerRole = 'ADMIN' | 'MEMBER'

export type GqlCustomerUserConnection = {
  __typename?: 'CustomerUserConnection'
  edges?: Maybe<Array<Maybe<GqlCustomerUserEdge>>>
  items?: Maybe<Array<Maybe<GqlUser>>>
  pageInfo: GqlPageInfo
  totalCount: Scalars['Int']['output']
}

export type GqlCustomerUserEdge = {
  __typename?: 'CustomerUserEdge'
  cursor: Scalars['String']['output']
  node?: Maybe<GqlUser>
  role: GqlCustomerRole
}

export type GqlDeclineFactoryInvitationInput = {
  invitationId: Scalars['ID']['input']
}

export type GqlDeclineFactoryInvitationPayload = {
  __typename?: 'DeclineFactoryInvitationPayload'
  invitation: GqlFactoryInvitation
}

export type GqlDeleteAssetInput = {
  id: Scalars['ID']['input']
}

export type GqlDeleteAssetPayload = {
  __typename?: 'DeleteAssetPayload'
  id: Scalars['ID']['output']
}

export type GqlDeleteModelInput = {
  id: Scalars['ID']['input']
}

export type GqlDeleteModelPayload = {
  __typename?: 'DeleteModelPayload'
  model: GqlModel
}

export type GqlDeleteTrendViewInput = {
  trendViewId: Scalars['ID']['input']
}

export type GqlDeleteTrendViewPayload = {
  __typename?: 'DeleteTrendViewPayload'
  trendView: GqlTrendView
}

export type GqlDisableUserInput = {
  id: Scalars['ID']['input']
}

export type GqlDisableUserPayload = {
  __typename?: 'DisableUserPayload'
  user: GqlUser
}

export type GqlDocumentationUrl = {
  __typename?: 'DocumentationUrl'
  expiry: Scalars['DateTime']['output']
  token: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type GqlEnableUserInput = {
  id: Scalars['ID']['input']
}

export type GqlEnableUserPayload = {
  __typename?: 'EnableUserPayload'
  user: GqlUser
}

export type GqlErrorScore = {
  __typename?: 'ErrorScore'
  kind: GqlErrorScoreKind
  robust: Scalars['Boolean']['output']
  seriesName: Scalars['String']['output']
  transform: GqlTransform
}

export type GqlErrorScoreKind = 'ABSOLUTE' | 'SIGNED'

export type GqlFactory = GqlNode & {
  __typename?: 'Factory'
  activeModelCreditsUsage?: Maybe<GqlActiveModelCreditsUsage>
  agent?: Maybe<GqlAgent>
  agents?: Maybe<GqlAgentConnection>
  anomalies?: Maybe<GqlAnomalyConnection>
  anomaly?: Maybe<GqlAnomaly>
  anomalyCountByState: Array<GqlAnomalyStateCount>
  assets?: Maybe<GqlAssetConnection>
  customer: GqlCustomer
  dexAccess: Scalars['Boolean']['output']
  hierarchy: GqlHierarchy
  id: Scalars['ID']['output']
  invitations?: Maybe<GqlFactoryInvitationConnection>
  latestAnomalyComments: Array<GqlAnomalyComment>
  model?: Maybe<GqlModel>
  models?: Maybe<GqlModelInterfaceConnection>
  name: Scalars['String']['output']
  tagByName?: Maybe<GqlTag>
  tags?: Maybe<GqlTagConnection>
  tagsByName: Array<Maybe<GqlTag>>
  trendViews?: Maybe<GqlTrendViewConnection>
  userCount: Scalars['Int']['output']
  users?: Maybe<GqlFactoryUserConnection>
  viewerRole?: Maybe<GqlFactoryRole>
}

export type GqlFactoryAgentArgs = {
  id: Scalars['ID']['input']
}

export type GqlFactoryAgentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
}

export type GqlFactoryAnomaliesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Scalars['DateTime']['input']>
  to?: InputMaybe<Scalars['DateTime']['input']>
}

export type GqlFactoryAnomalyArgs = {
  id: Scalars['ID']['input']
}

export type GqlFactoryAnomalyCountByStateArgs = {
  from?: InputMaybe<Scalars['DateTime']['input']>
  to?: InputMaybe<Scalars['DateTime']['input']>
}

export type GqlFactoryAssetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  search?: InputMaybe<Scalars['String']['input']>
}

export type GqlFactoryHierarchyArgs = {
  view: GqlAssetViewType
}

export type GqlFactoryInvitationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
}

export type GqlFactoryLatestAnomalyCommentsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
}

export type GqlFactoryModelArgs = {
  id: Scalars['ID']['input']
}

export type GqlFactoryModelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  methodId?: InputMaybe<Scalars['ID']['input']>
  state?: InputMaybe<GqlModelState>
  typeId?: InputMaybe<Scalars['ID']['input']>
}

export type GqlFactoryTagByNameArgs = {
  tagname: Scalars['String']['input']
}

export type GqlFactoryTagsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
}

export type GqlFactoryTagsByNameArgs = {
  tagnames: Array<Scalars['String']['input']>
}

export type GqlFactoryTrendViewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
}

export type GqlFactoryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  status?: InputMaybe<GqlUserStatus>
}

export type GqlFactoryConnection = {
  __typename?: 'FactoryConnection'
  edges?: Maybe<Array<Maybe<GqlFactoryEdge>>>
  items?: Maybe<Array<Maybe<GqlFactory>>>
  pageInfo: GqlPageInfo
  totalCount: Scalars['Int']['output']
}

export type GqlFactoryEdge = {
  __typename?: 'FactoryEdge'
  cursor: Scalars['String']['output']
  node?: Maybe<GqlFactory>
}

export type GqlFactoryInvitation = GqlNode & {
  __typename?: 'FactoryInvitation'
  completedAt?: Maybe<Scalars['DateTime']['output']>
  createdAt: Scalars['DateTime']['output']
  factory: GqlFactory
  id: Scalars['ID']['output']
  invitee: GqlUser
  inviter: GqlUser
  role: GqlFactoryRole
  state: GqlInvitationState
  validUntil: Scalars['DateTime']['output']
}

export type GqlFactoryInvitationConnection = {
  __typename?: 'FactoryInvitationConnection'
  edges?: Maybe<Array<Maybe<GqlFactoryInvitationEdge>>>
  items?: Maybe<Array<Maybe<GqlFactoryInvitation>>>
  pageInfo: GqlPageInfo
  totalCount?: Maybe<Scalars['Int']['output']>
}

export type GqlFactoryInvitationEdge = {
  __typename?: 'FactoryInvitationEdge'
  cursor: Scalars['String']['output']
  node?: Maybe<GqlFactoryInvitation>
}

export type GqlFactoryRole = 'ADMIN' | 'READER' | 'WRITER'

export type GqlFactoryRoleInput = {
  factoryId: Scalars['ID']['input']
  role: GqlFactoryRole
}

export type GqlFactoryUserConnection = {
  __typename?: 'FactoryUserConnection'
  edges?: Maybe<Array<Maybe<GqlFactoryUserEdge>>>
  items?: Maybe<Array<Maybe<GqlUser>>>
  pageInfo: GqlPageInfo
  totalCount: Scalars['Int']['output']
}

export type GqlFactoryUserEdge = {
  __typename?: 'FactoryUserEdge'
  cursor: Scalars['String']['output']
  node?: Maybe<GqlUser>
  role: GqlFactoryRole
}

export type GqlForecastModel = GqlModel &
  GqlNode &
  GqlStarrable & {
    __typename?: 'ForecastModel'
    activated?: Maybe<Scalars['DateTime']['output']>
    activeTrainedModel?: Maybe<GqlTrainedModel>
    created: Scalars['DateTime']['output']
    createdBy?: Maybe<GqlUser>
    description: Scalars['String']['output']
    errorMessage?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    initialInferenceEnd?: Maybe<Scalars['DateTime']['output']>
    initialInferenceStart?: Maybe<Scalars['DateTime']['output']>
    inputTags: Array<GqlTag>
    lastStateChange: Scalars['DateTime']['output']
    method: GqlModelMethod
    methodId: Scalars['ID']['output']
    name: Scalars['String']['output']
    state: GqlModelState
    tag: GqlTag
    tagId: Scalars['ID']['output']
    trainedModels: Array<GqlTrainedModel>
    trainingEnd?: Maybe<Scalars['DateTime']['output']>
    trainingPeriod?: Maybe<Scalars['Period']['output']>
    trainingStart?: Maybe<Scalars['DateTime']['output']>
    type: GqlModelType
    typeId: Scalars['ID']['output']
    viewerHasStarred: Scalars['Boolean']['output']
  }

export type GqlHierarchy = {
  __typename?: 'Hierarchy'
  assets?: Maybe<GqlHierarchyAssetConnection>
  tags?: Maybe<GqlHierarchyTagConnection>
  view: GqlAssetViewType
}

export type GqlHierarchyAssetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
}

export type GqlHierarchyTagsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
}

export type GqlHierarchyAsset = GqlNode & {
  __typename?: 'HierarchyAsset'
  asset: GqlAsset
  assetId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  parentAsset?: Maybe<GqlAsset>
  parentAssetId?: Maybe<Scalars['ID']['output']>
  viewType: GqlAssetViewType
}

export type GqlHierarchyAssetConnection = {
  __typename?: 'HierarchyAssetConnection'
  edges?: Maybe<Array<Maybe<GqlHierarchyAssetEdge>>>
  items?: Maybe<Array<Maybe<GqlHierarchyAsset>>>
  pageInfo: GqlPageInfo
  totalCount?: Maybe<Scalars['Int']['output']>
}

export type GqlHierarchyAssetEdge = {
  __typename?: 'HierarchyAssetEdge'
  cursor: Scalars['String']['output']
  node?: Maybe<GqlHierarchyAsset>
}

export type GqlHierarchyTag = GqlNode & {
  __typename?: 'HierarchyTag'
  asset: GqlAsset
  assetId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  tag: GqlTag
  tagId: Scalars['ID']['output']
  viewType: GqlAssetViewType
}

export type GqlHierarchyTagConnection = {
  __typename?: 'HierarchyTagConnection'
  edges?: Maybe<Array<Maybe<GqlHierarchyTagEdge>>>
  items?: Maybe<Array<Maybe<GqlHierarchyTag>>>
  pageInfo: GqlPageInfo
  totalCount?: Maybe<Scalars['Int']['output']>
}

export type GqlHierarchyTagEdge = {
  __typename?: 'HierarchyTagEdge'
  cursor: Scalars['String']['output']
  node?: Maybe<GqlHierarchyTag>
}

export type GqlInvitationState =
  | 'ACCEPTED'
  | 'ACTIVE'
  | 'CANCELLED'
  | 'DECLINED'
  | 'EXPIRED'

export type GqlInviteFactoryUserInput = {
  email: Scalars['String']['input']
  factoryId: Scalars['ID']['input']
  role: GqlFactoryRole
}

export type GqlInviteFactoryUserPayload = {
  __typename?: 'InviteFactoryUserPayload'
  invitation: GqlFactoryInvitation
}

export type GqlModel = {
  activated?: Maybe<Scalars['DateTime']['output']>
  activeTrainedModel?: Maybe<GqlTrainedModel>
  created: Scalars['DateTime']['output']
  createdBy?: Maybe<GqlUser>
  description: Scalars['String']['output']
  errorMessage?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  inputTags?: Maybe<Array<Maybe<GqlTag>>>
  lastStateChange: Scalars['DateTime']['output']
  method?: Maybe<GqlModelMethod>
  methodId: Scalars['ID']['output']
  name: Scalars['String']['output']
  state: GqlModelState
  tag?: Maybe<GqlTag>
  tagId: Scalars['ID']['output']
  trainedModels?: Maybe<Array<Maybe<GqlTrainedModel>>>
  trainingEnd?: Maybe<Scalars['DateTime']['output']>
  trainingPeriod?: Maybe<Scalars['Period']['output']>
  trainingStart?: Maybe<Scalars['DateTime']['output']>
  type?: Maybe<GqlModelType>
  typeId: Scalars['ID']['output']
}

export type GqlModelInterfaceConnection = {
  __typename?: 'ModelInterfaceConnection'
  edges?: Maybe<Array<Maybe<GqlModelInterfaceEdge>>>
  items?: Maybe<Array<Maybe<GqlModel>>>
  pageInfo: GqlPageInfo
  totalCount?: Maybe<Scalars['Int']['output']>
}

export type GqlModelInterfaceEdge = {
  __typename?: 'ModelInterfaceEdge'
  cursor: Scalars['String']['output']
  node?: Maybe<GqlModel>
}

export type GqlModelMethod = GqlNode & {
  __typename?: 'ModelMethod'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type GqlModelState =
  | 'ARCHIVED'
  | 'AWAITING_ANOMALY_ANALYSIS'
  | 'DELETED'
  | 'ERROR'
  | 'INITIAL_ANOMALY_DETECTION'
  | 'INITIAL_INFERENCE'
  | 'NEW'
  | 'NOT_RUNNING'
  | 'RUNNING'
  | 'STREAMING'
  | 'TRAINING'

export type GqlModelType = GqlNode & {
  __typename?: 'ModelType'
  defaultMethod: GqlModelMethod
  defaultMethodId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  methods: Array<GqlModelMethod>
  name: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type GqlMutation = {
  __typename?: 'Mutation'
  acceptFactoryInvitation?: Maybe<GqlAcceptFactoryInvitationPayload>
  activateModel?: Maybe<GqlActivateModelPayload>
  addAnomalyComment?: Maybe<GqlAddAnomalyCommentPayload>
  addInputTagsToModel?: Maybe<GqlAddInputTagsToModelPayload>
  addStar?: Maybe<GqlAddStarPayload>
  addUser?: Maybe<GqlAddUserPayload>
  archiveModel?: Maybe<GqlArchiveModelPayload>
  cancelFactoryInvitation?: Maybe<GqlCancelFactoryInvitationPayload>
  createAsset?: Maybe<GqlCreateAssetPayload>
  createModel?: Maybe<GqlCreateModelPayload>
  createOpcConnection?: Maybe<GqlCreateOpcConnectionPayload>
  createTrendView?: Maybe<GqlCreateTrendViewPayload>
  declineFactoryInvitation?: Maybe<GqlDeclineFactoryInvitationPayload>
  deleteAsset?: Maybe<GqlDeleteAssetPayload>
  deleteModel?: Maybe<GqlDeleteModelPayload>
  deleteTrendView?: Maybe<GqlDeleteTrendViewPayload>
  disableUser?: Maybe<GqlDisableUserPayload>
  enableUser?: Maybe<GqlEnableUserPayload>
  inviteFactoryUser?: Maybe<GqlInviteFactoryUserPayload>
  removeCustomerUser?: Maybe<GqlRemoveCustomerUserPayload>
  removeFactoryUser?: Maybe<GqlRemoveFactoryUserPayload>
  removeInputTagsFromModel?: Maybe<GqlRemoveInputTagsFromModelPayload>
  removeStar?: Maybe<GqlRemoveStarPayload>
  runAnomalyDetection?: Maybe<GqlRunAnomalyDetectionPayload>
  setAnomalyState?: Maybe<GqlSetAnomalyStatePayload>
  setAssetParent?: Maybe<GqlSetAssetParentPayload>
  setCustomerUserRole?: Maybe<GqlSetCustomerUserRolePayload>
  setFactoryUserRole?: Maybe<GqlSetFactoryUserRolePayload>
  setTagsParent?: Maybe<GqlSetTagsParentPayload>
  startModel?: Maybe<GqlStartModelPayload>
  stopModel?: Maybe<GqlStopModelPayload>
  updateAsset?: Maybe<GqlUpdateAssetPayload>
  updateModel?: Maybe<GqlUpdateModelPayload>
  updateModelAnomalyAnalysisComplete?: Maybe<GqlUpdateModelAnomalyAnalysisCompletePayload>
  updateModelAnomalyConditions?: Maybe<GqlUpdateModelAnomalyConditionsPayload>
  updateModelFilter?: Maybe<GqlUpdateModelFilterPayload>
  updateModelInitialAnomalyDetectionComplete?: Maybe<GqlUpdateModelInitialAnomalyDetectionCompletePayload>
  updateModelInitialInferenceComplete?: Maybe<GqlUpdateModelInitialInferenceCompletePayload>
  updateModelThresholds?: Maybe<GqlUpdateModelThresholdsPayload>
  updateOpcConnection?: Maybe<GqlUpdateOpcConnectionPayload>
  updateTag?: Maybe<GqlUpdateTagPayload>
  updateTrainedModel?: Maybe<GqlUpdateTrainedModelPayload>
  updateTrainingTask?: Maybe<GqlUpdateTrainingTaskPayload>
  updateTrendView?: Maybe<GqlUpdateTrendViewPayload>
  updateUserConsent?: Maybe<GqlUpdateUserConsentPayload>
  updateUserDefaultFactory?: Maybe<GqlUpdateUserDefaultFactoryPayload>
  updateUserName?: Maybe<GqlUpdateUserNamePayload>
}

export type GqlMutationAcceptFactoryInvitationArgs = {
  input: GqlAcceptFactoryInvitationInput
}

export type GqlMutationActivateModelArgs = {
  input: GqlActivateModelInput
}

export type GqlMutationAddAnomalyCommentArgs = {
  input: GqlAddAnomalyCommentInput
}

export type GqlMutationAddInputTagsToModelArgs = {
  input: GqlAddInputTagsToModelInput
}

export type GqlMutationAddStarArgs = {
  input: GqlAddStarInput
}

export type GqlMutationAddUserArgs = {
  input: GqlAddUserInput
}

export type GqlMutationArchiveModelArgs = {
  input: GqlArchiveModelInput
}

export type GqlMutationCancelFactoryInvitationArgs = {
  input: GqlCancelFactoryInvitationInput
}

export type GqlMutationCreateAssetArgs = {
  input: GqlCreateAssetInput
}

export type GqlMutationCreateModelArgs = {
  input: GqlCreateModelInput
}

export type GqlMutationCreateOpcConnectionArgs = {
  input: GqlCreateOpcConnectionInput
}

export type GqlMutationCreateTrendViewArgs = {
  input: GqlCreateTrendViewInput
}

export type GqlMutationDeclineFactoryInvitationArgs = {
  input: GqlDeclineFactoryInvitationInput
}

export type GqlMutationDeleteAssetArgs = {
  input: GqlDeleteAssetInput
}

export type GqlMutationDeleteModelArgs = {
  input: GqlDeleteModelInput
}

export type GqlMutationDeleteTrendViewArgs = {
  input: GqlDeleteTrendViewInput
}

export type GqlMutationDisableUserArgs = {
  input: GqlDisableUserInput
}

export type GqlMutationEnableUserArgs = {
  input: GqlEnableUserInput
}

export type GqlMutationInviteFactoryUserArgs = {
  input: GqlInviteFactoryUserInput
}

export type GqlMutationRemoveCustomerUserArgs = {
  input: GqlRemoveCustomerUserInput
}

export type GqlMutationRemoveFactoryUserArgs = {
  input: GqlRemoveFactoryUserInput
}

export type GqlMutationRemoveInputTagsFromModelArgs = {
  input: GqlRemoveInputTagsFromModelInput
}

export type GqlMutationRemoveStarArgs = {
  input: GqlRemoveStarInput
}

export type GqlMutationRunAnomalyDetectionArgs = {
  input: GqlRunAnomalyDetectionInput
}

export type GqlMutationSetAnomalyStateArgs = {
  input: GqlSetAnomalyStateInput
}

export type GqlMutationSetAssetParentArgs = {
  input: GqlSetAssetParentInput
}

export type GqlMutationSetCustomerUserRoleArgs = {
  input: GqlSetCustomerUserRoleInput
}

export type GqlMutationSetFactoryUserRoleArgs = {
  input: GqlSetFactoryUserRoleInput
}

export type GqlMutationSetTagsParentArgs = {
  input: GqlSetTagsParentInput
}

export type GqlMutationStartModelArgs = {
  input: GqlStartModelInput
}

export type GqlMutationStopModelArgs = {
  input: GqlStopModelInput
}

export type GqlMutationUpdateAssetArgs = {
  input: GqlUpdateAssetInput
}

export type GqlMutationUpdateModelArgs = {
  input: GqlUpdateModelInput
}

export type GqlMutationUpdateModelAnomalyAnalysisCompleteArgs = {
  input: GqlUpdateModelAnomalyAnalysisCompleteInput
}

export type GqlMutationUpdateModelAnomalyConditionsArgs = {
  input: GqlUpdateModelAnomalyConditionsInput
}

export type GqlMutationUpdateModelFilterArgs = {
  input: GqlUpdateModelFilterInput
}

export type GqlMutationUpdateModelInitialAnomalyDetectionCompleteArgs = {
  input: GqlUpdateModelInitialAnomalyDetectionCompleteInput
}

export type GqlMutationUpdateModelInitialInferenceCompleteArgs = {
  input: GqlUpdateModelInitialInferenceCompleteInput
}

export type GqlMutationUpdateModelThresholdsArgs = {
  input: GqlUpdateModelThresholdsInput
}

export type GqlMutationUpdateOpcConnectionArgs = {
  input: GqlUpdateOpcConnectionInput
}

export type GqlMutationUpdateTagArgs = {
  input: GqlUpdateTagInput
}

export type GqlMutationUpdateTrainedModelArgs = {
  input: GqlUpdateTrainedModelInput
}

export type GqlMutationUpdateTrainingTaskArgs = {
  input: GqlUpdateTrainingTaskInput
}

export type GqlMutationUpdateTrendViewArgs = {
  input: GqlUpdateTrendViewInput
}

export type GqlMutationUpdateUserConsentArgs = {
  input: GqlUpdateUserConsentInput
}

export type GqlMutationUpdateUserDefaultFactoryArgs = {
  input: GqlUpdateUserDefaultFactoryInput
}

export type GqlMutationUpdateUserNameArgs = {
  input: GqlUpdateUserNameInput
}

export type GqlNode = {
  id: Scalars['ID']['output']
}

export type GqlObjectOpcNode = GqlOpcNode & {
  __typename?: 'ObjectOpcNode'
  browseName: Scalars['String']['output']
  description: Scalars['String']['output']
  displayName: Scalars['String']['output']
  nodeId: Scalars['String']['output']
}

export type GqlOpcApplicationDescription = {
  __typename?: 'OpcApplicationDescription'
  applicationName: Scalars['String']['output']
  applicationType: Scalars['String']['output']
  applicationURI: Scalars['String']['output']
  discoveryProfileURI: Scalars['String']['output']
  discoveryUrls: Array<Scalars['String']['output']>
  gatewayServerURI: Scalars['String']['output']
  productURI: Scalars['String']['output']
}

export type GqlOpcBrowseResponse = {
  __typename?: 'OpcBrowseResponse'
  children: Array<GqlOpcNode>
  node: GqlOpcNode
}

export type GqlOpcConnection = GqlNode & {
  __typename?: 'OpcConnection'
  agentId: Scalars['ID']['output']
  browse: GqlOpcBrowseResponse
  configurationState: GqlOpcConnectionConfigurationState
  endpoint: Scalars['Uri']['output']
  factoryId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  ignoreServerEndpoint: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  readValues: Array<GqlOpcReadResult>
  securityMode?: Maybe<GqlOpcSecurityMode>
  securityPolicy?: Maybe<GqlOpcSecurityPolicy>
  username?: Maybe<Scalars['String']['output']>
  x509Thumbprint?: Maybe<Scalars['String']['output']>
}

export type GqlOpcConnectionBrowseArgs = {
  nodeId: Scalars['String']['input']
  timeout?: Scalars['Milliseconds']['input']
}

export type GqlOpcConnectionReadValuesArgs = {
  nodeIds: Array<Scalars['String']['input']>
  timeout?: Scalars['Milliseconds']['input']
}

export type GqlOpcConnectionConfigurationState = 'COMPLETE' | 'INCOMPLETE'

export type GqlOpcConnectionConnection = {
  __typename?: 'OpcConnectionConnection'
  edges?: Maybe<Array<Maybe<GqlOpcConnectionEdge>>>
  items?: Maybe<Array<Maybe<GqlOpcConnection>>>
  pageInfo: GqlPageInfo
  totalCount?: Maybe<Scalars['Int']['output']>
}

export type GqlOpcConnectionEdge = {
  __typename?: 'OpcConnectionEdge'
  cursor: Scalars['String']['output']
  node?: Maybe<GqlOpcConnection>
}

export type GqlOpcCredentialsInput = {
  password: Scalars['String']['input']
  username: Scalars['String']['input']
}

export type GqlOpcDataValue = {
  __typename?: 'OpcDataValue'
  provenance: Scalars['String']['output']
  serverTimestamp?: Maybe<Scalars['DateTime']['output']>
  sourceTimestamp?: Maybe<Scalars['DateTime']['output']>
  status: GqlOpcStatus
  value: GqlOpcValue
}

export type GqlOpcEndpointDescription = {
  __typename?: 'OpcEndpointDescription'
  securityMode: GqlOpcSecurityMode
  securityPolicy: GqlOpcSecurityPolicy
  server?: Maybe<GqlOpcApplicationDescription>
  serverThumbprint: Scalars['String']['output']
  url: Scalars['String']['output']
  userIdentityTokens: Array<GqlOpcUserIdentityToken>
}

export type GqlOpcNode = {
  browseName: Scalars['String']['output']
  description?: Maybe<Scalars['String']['output']>
  displayName: Scalars['String']['output']
  nodeId: Scalars['String']['output']
}

export type GqlOpcReadResult = {
  __typename?: 'OpcReadResult'
  nodeId: Scalars['String']['output']
  value?: Maybe<GqlOpcDataValue>
}

export type GqlOpcSecurityMode =
  | 'INVALID'
  | 'NONE'
  | 'SIGN'
  | 'SIGN_AND_ENCRYPT'

export type GqlOpcSecurityPolicy =
  | 'AES_128_SHA_256_RSA_OAEP'
  | 'AES_256_SHA_256_RSA_PSS'
  | 'BASIC_128_RSA_15'
  | 'BASIC_256'
  | 'BASIC_256_SHA_256'
  | 'NONE'

export type GqlOpcStatus = {
  __typename?: 'OpcStatus'
  severity: GqlOpcStatusSeverity
  value: Scalars['UInt']['output']
}

export type GqlOpcStatusSeverity = 'BAD' | 'GOOD' | 'UNCERTAIN'

export type GqlOpcStream = GqlNode & {
  __typename?: 'OpcStream'
  agent: GqlAgent
  agentId: Scalars['ID']['output']
  autoStart: Scalars['Boolean']['output']
  batchInterval: Scalars['Milliseconds']['output']
  heartbeatInterval: Scalars['Milliseconds']['output']
  heartbeatNode: Scalars['String']['output']
  id: Scalars['ID']['output']
  lastUpdated: Scalars['DateTime']['output']
  nodes?: Maybe<GqlOpcStreamNodeConnection>
  opcConnection: GqlOpcConnection
  opcConnectionId: Scalars['ID']['output']
  revision: Scalars['Int']['output']
}

export type GqlOpcStreamNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
}

export type GqlOpcStreamConnection = {
  __typename?: 'OpcStreamConnection'
  edges?: Maybe<Array<Maybe<GqlOpcStreamEdge>>>
  items?: Maybe<Array<Maybe<GqlOpcStream>>>
  pageInfo: GqlPageInfo
  totalCount?: Maybe<Scalars['Int']['output']>
}

export type GqlOpcStreamEdge = {
  __typename?: 'OpcStreamEdge'
  cursor: Scalars['String']['output']
  node?: Maybe<GqlOpcStream>
}

export type GqlOpcStreamNode = {
  __typename?: 'OpcStreamNode'
  nodeId: Scalars['String']['output']
}

export type GqlOpcStreamNodeConnection = {
  __typename?: 'OpcStreamNodeConnection'
  edges?: Maybe<Array<Maybe<GqlOpcStreamNodeEdge>>>
  items?: Maybe<Array<Maybe<GqlOpcStreamNode>>>
  pageInfo: GqlPageInfo
  totalCount?: Maybe<Scalars['Int']['output']>
}

export type GqlOpcStreamNodeEdge = {
  __typename?: 'OpcStreamNodeEdge'
  cursor: Scalars['String']['output']
  node?: Maybe<GqlOpcStreamNode>
}

export type GqlOpcUserIdentityToken = {
  __typename?: 'OpcUserIdentityToken'
  issuedTokenType: Scalars['String']['output']
  issuerEndpointUrl: Scalars['String']['output']
  policyId: Scalars['String']['output']
  securityPolicy?: Maybe<GqlOpcSecurityPolicy>
  userTokenType: GqlOpcUserTokenType
}

export type GqlOpcUserTokenType =
  | 'ANONYMOUS'
  | 'CERTIFICATE'
  | 'ISSUED_TOKEN'
  | 'USER_NAME'

export type GqlOpcValue =
  | GqlOpcValueBoolean
  | GqlOpcValueDateTime
  | GqlOpcValueDouble
  | GqlOpcValueError
  | GqlOpcValueFloat
  | GqlOpcValueInt
  | GqlOpcValueLong
  | GqlOpcValueShort
  | GqlOpcValueString
  | GqlOpcValueUInt
  | GqlOpcValueULong
  | GqlOpcValueUShort

export type GqlOpcValueBoolean = {
  __typename?: 'OpcValueBoolean'
  value: Scalars['Boolean']['output']
}

export type GqlOpcValueDateTime = {
  __typename?: 'OpcValueDateTime'
  value: Scalars['DateTime']['output']
}

export type GqlOpcValueDouble = {
  __typename?: 'OpcValueDouble'
  value: Scalars['Float']['output']
}

export type GqlOpcValueError = {
  __typename?: 'OpcValueError'
  error: Scalars['String']['output']
}

export type GqlOpcValueFloat = {
  __typename?: 'OpcValueFloat'
  value: Scalars['Float']['output']
}

export type GqlOpcValueInt = {
  __typename?: 'OpcValueInt'
  value: Scalars['Int']['output']
}

export type GqlOpcValueLong = {
  __typename?: 'OpcValueLong'
  value: Scalars['Long']['output']
}

export type GqlOpcValueShort = {
  __typename?: 'OpcValueShort'
  value: Scalars['Int']['output']
}

export type GqlOpcValueString = {
  __typename?: 'OpcValueString'
  value: Scalars['String']['output']
}

export type GqlOpcValueUInt = {
  __typename?: 'OpcValueUInt'
  value: Scalars['UInt']['output']
}

export type GqlOpcValueULong = {
  __typename?: 'OpcValueULong'
  value: Scalars['ULong']['output']
}

export type GqlOpcValueUShort = {
  __typename?: 'OpcValueUShort'
  value: Scalars['UInt']['output']
}

export type GqlPageInfo = {
  __typename?: 'PageInfo'
  endCursor?: Maybe<Scalars['String']['output']>
  hasNextPage: Scalars['Boolean']['output']
  hasPreviousPage: Scalars['Boolean']['output']
  startCursor?: Maybe<Scalars['String']['output']>
}

export type GqlProcessDataEventTimeRange = {
  __typename?: 'ProcessDataEventTimeRange'
  max: Scalars['DateTime']['output']
  min: Scalars['DateTime']['output']
}

export type GqlQuery = {
  __typename?: 'Query'
  customer?: Maybe<GqlCustomer>
  documentationUrl: GqlDocumentationUrl
  factory?: Maybe<GqlFactory>
  me: GqlUser
  modelTypes: Array<GqlModelType>
  node?: Maybe<GqlNode>
}

export type GqlQueryCustomerArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type GqlQueryFactoryArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type GqlQueryNodeArgs = {
  id: Scalars['ID']['input']
}

export type GqlRemoveCustomerUserInput = {
  customerId: Scalars['ID']['input']
  userId: Scalars['ID']['input']
}

export type GqlRemoveCustomerUserPayload = {
  __typename?: 'RemoveCustomerUserPayload'
  customer: GqlCustomer
  userEdge: GqlCustomerUserEdge
}

export type GqlRemoveFactoryUserInput = {
  factoryId: Scalars['ID']['input']
  userId: Scalars['ID']['input']
}

export type GqlRemoveFactoryUserPayload = {
  __typename?: 'RemoveFactoryUserPayload'
  factory?: Maybe<GqlFactory>
  userEdge?: Maybe<GqlFactoryUserEdge>
}

export type GqlRemoveInputTagsFromModelInput = {
  id: Scalars['ID']['input']
  tagIds: Array<Scalars['ID']['input']>
}

export type GqlRemoveInputTagsFromModelPayload = {
  __typename?: 'RemoveInputTagsFromModelPayload'
  model: GqlModel
}

export type GqlRemoveStarInput = {
  starrableId: Scalars['ID']['input']
}

export type GqlRemoveStarPayload = {
  __typename?: 'RemoveStarPayload'
  starrable?: Maybe<GqlStarrable>
}

export type GqlRunAnomalyDetectionInput = {
  anomalyGenerationFilter?: InputMaybe<Scalars['String']['input']>
  conditions?: InputMaybe<Array<GqlAnomalyConditionInput>>
  id: Scalars['ID']['input']
  replaceAnomalies?: Scalars['Boolean']['input']
  shortAnomalyThreshold?: InputMaybe<Scalars['Float']['input']>
  spikeAnomalyThreshold?: InputMaybe<Scalars['Float']['input']>
}

export type GqlRunAnomalyDetectionPayload = {
  __typename?: 'RunAnomalyDetectionPayload'
  model: GqlModel
}

export type GqlSetAnomalyStateInput = {
  anomalyId: Scalars['ID']['input']
  state: GqlAnomalyState
}

export type GqlSetAnomalyStatePayload = {
  __typename?: 'SetAnomalyStatePayload'
  anomaly: GqlAnomaly
}

export type GqlSetAssetParentInput = {
  assetId: Scalars['ID']['input']
  factoryId: Scalars['ID']['input']
  parentAssetId?: InputMaybe<Scalars['ID']['input']>
  view?: GqlAssetViewType
}

export type GqlSetAssetParentPayload = {
  __typename?: 'SetAssetParentPayload'
  assetId: Scalars['ID']['output']
  factoryId: Scalars['ID']['output']
  parentAssetId?: Maybe<Scalars['ID']['output']>
  view: GqlAssetViewType
}

export type GqlSetCustomerUserRoleInput = {
  customerId: Scalars['ID']['input']
  role: GqlCustomerRole
  userId: Scalars['ID']['input']
}

export type GqlSetCustomerUserRolePayload = {
  __typename?: 'SetCustomerUserRolePayload'
  customer?: Maybe<GqlCustomer>
  userEdge?: Maybe<GqlCustomerUserEdge>
}

export type GqlSetFactoryUserRoleInput = {
  factoryId: Scalars['ID']['input']
  role: GqlFactoryRole
  userId: Scalars['ID']['input']
}

export type GqlSetFactoryUserRolePayload = {
  __typename?: 'SetFactoryUserRolePayload'
  factory?: Maybe<GqlFactory>
  userEdge?: Maybe<GqlFactoryUserEdge>
}

export type GqlSetTagsParentInput = {
  assetId: Scalars['ID']['input']
  factoryId: Scalars['ID']['input']
  tagIds: Array<Scalars['ID']['input']>
  view?: GqlAssetViewType
}

export type GqlSetTagsParentPayload = {
  __typename?: 'SetTagsParentPayload'
  assetId: Scalars['ID']['output']
  factoryId: Scalars['ID']['output']
  tagIds: Array<Scalars['ID']['output']>
  view: GqlAssetViewType
}

export type GqlStarrable = {
  viewerHasStarred: Scalars['Boolean']['output']
}

export type GqlStartModelInput = {
  id: Scalars['ID']['input']
}

export type GqlStartModelPayload = {
  __typename?: 'StartModelPayload'
  model: GqlModel
}

export type GqlStopModelInput = {
  id: Scalars['ID']['input']
}

export type GqlStopModelPayload = {
  __typename?: 'StopModelPayload'
  model: GqlModel
}

export type GqlTag = GqlNode & {
  __typename?: 'Tag'
  availableDataTimeRange?: Maybe<GqlProcessDataEventTimeRange>
  description?: Maybe<Scalars['String']['output']>
  displayName?: Maybe<Scalars['String']['output']>
  engUnit?: Maybe<Scalars['String']['output']>
  hierarchyTag?: Maybe<GqlHierarchyTag>
  id: Scalars['ID']['output']
  metadata?: Maybe<GqlTagMetadata>
  processData?: Maybe<GqlTimeSeries>
  tagName: Scalars['String']['output']
  valueType?: Maybe<Scalars['String']['output']>
}

export type GqlTagHierarchyTagArgs = {
  view?: InputMaybe<GqlAssetViewType>
}

export type GqlTagProcessDataArgs = {
  from: Scalars['DateTime']['input']
  interval: Scalars['Int']['input']
  to: Scalars['DateTime']['input']
}

export type GqlTagConnection = {
  __typename?: 'TagConnection'
  edges?: Maybe<Array<Maybe<GqlTagEdge>>>
  items?: Maybe<Array<Maybe<GqlTag>>>
  pageInfo: GqlPageInfo
  totalCount?: Maybe<Scalars['Int']['output']>
}

export type GqlTagEdge = {
  __typename?: 'TagEdge'
  cursor: Scalars['String']['output']
  node?: Maybe<GqlTag>
}

export type GqlTagMetadata = {
  __typename?: 'TagMetadata'
  isBad?: Maybe<Scalars['Boolean']['output']>
  label?: Maybe<Scalars['String']['output']>
}

export type GqlThresholdKind = 'LOWER' | 'UPPER'

export type GqlTimeSeries = {
  __typename?: 'TimeSeries'
  data: Array<GqlTimeSeriesData>
  from: Scalars['DateTime']['output']
  interval: Scalars['Int']['output']
  to: Scalars['DateTime']['output']
}

export type GqlTimeSeriesData = {
  __typename?: 'TimeSeriesData'
  avg: Scalars['Float']['output']
  max: Scalars['Float']['output']
  min: Scalars['Float']['output']
  timestamp: Scalars['DateTime']['output']
}

export type GqlTrainedModel = GqlNode & {
  __typename?: 'TrainedModel'
  anomalyCount?: Maybe<Scalars['Int']['output']>
  created: Scalars['DateTime']['output']
  dataEnd: Scalars['DateTime']['output']
  dataStart: Scalars['DateTime']['output']
  dockerImage: Scalars['String']['output']
  id: Scalars['ID']['output']
  loss?: Maybe<Scalars['Float']['output']>
  mae?: Maybe<Scalars['Float']['output']>
  mape?: Maybe<Scalars['Float']['output']>
  meanLabel?: Maybe<Scalars['Float']['output']>
  meanPred?: Maybe<Scalars['Float']['output']>
  modelId: Scalars['ID']['output']
  rSquare?: Maybe<Scalars['Float']['output']>
  valLoss?: Maybe<Scalars['Float']['output']>
  valMae?: Maybe<Scalars['Float']['output']>
  valMape?: Maybe<Scalars['Float']['output']>
  valMeanLabel?: Maybe<Scalars['Float']['output']>
  valMeanPred?: Maybe<Scalars['Float']['output']>
  valRSquare?: Maybe<Scalars['Float']['output']>
}

export type GqlTrainingTask = GqlNode & {
  __typename?: 'TrainingTask'
  id: Scalars['ID']['output']
  queueId?: Maybe<Scalars['String']['output']>
  successful?: Maybe<Scalars['Boolean']['output']>
  taskId?: Maybe<Scalars['String']['output']>
}

export type GqlTransform = 'NONE' | 'SMOOTHED'

export type GqlTrendView = GqlNode & {
  __typename?: 'TrendView'
  createdAt: Scalars['DateTime']['output']
  data: Scalars['String']['output']
  factoryId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  shared: Scalars['Boolean']['output']
  updatedAt: Scalars['DateTime']['output']
  user: GqlUser
  userId: Scalars['ID']['output']
}

export type GqlTrendViewConnection = {
  __typename?: 'TrendViewConnection'
  edges?: Maybe<Array<Maybe<GqlTrendViewEdge>>>
  items?: Maybe<Array<Maybe<GqlTrendView>>>
  pageInfo: GqlPageInfo
  totalCount?: Maybe<Scalars['Int']['output']>
}

export type GqlTrendViewEdge = {
  __typename?: 'TrendViewEdge'
  cursor: Scalars['String']['output']
  node?: Maybe<GqlTrendView>
}

export type GqlUnknownOpcNode = GqlOpcNode & {
  __typename?: 'UnknownOpcNode'
  browseName: Scalars['String']['output']
  description: Scalars['String']['output']
  displayName: Scalars['String']['output']
  nodeId: Scalars['String']['output']
}

export type GqlUpdateAssetInput = {
  alias?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

export type GqlUpdateAssetPayload = {
  __typename?: 'UpdateAssetPayload'
  asset: GqlAsset
}

export type GqlUpdateModelAnomalyAnalysisCompleteInput = {
  id: Scalars['ID']['input']
}

export type GqlUpdateModelAnomalyAnalysisCompletePayload = {
  __typename?: 'UpdateModelAnomalyAnalysisCompletePayload'
  model: GqlModel
}

export type GqlUpdateModelAnomalyConditionsInput = {
  conditions: Array<GqlAnomalyConditionInput>
  modelId: Scalars['ID']['input']
}

export type GqlUpdateModelAnomalyConditionsPayload = {
  __typename?: 'UpdateModelAnomalyConditionsPayload'
  model: GqlModel
}

export type GqlUpdateModelFilterInput = {
  anomalyGenerationFilter?: InputMaybe<Scalars['String']['input']>
  modelId: Scalars['ID']['input']
}

export type GqlUpdateModelFilterPayload = {
  __typename?: 'UpdateModelFilterPayload'
  model: GqlModel
}

export type GqlUpdateModelInitialAnomalyDetectionCompleteInput = {
  id: Scalars['ID']['input']
  success: Scalars['Boolean']['input']
}

export type GqlUpdateModelInitialAnomalyDetectionCompletePayload = {
  __typename?: 'UpdateModelInitialAnomalyDetectionCompletePayload'
  model: GqlModel
}

export type GqlUpdateModelInitialInferenceCompleteInput = {
  id: Scalars['ID']['input']
  success: Scalars['Boolean']['input']
}

export type GqlUpdateModelInitialInferenceCompletePayload = {
  __typename?: 'UpdateModelInitialInferenceCompletePayload'
  model: GqlModel
}

export type GqlUpdateModelInput = {
  description?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  inputTagIds?: InputMaybe<Array<Scalars['ID']['input']>>
  methodId?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  tagId?: InputMaybe<Scalars['ID']['input']>
  trainingEnd?: InputMaybe<Scalars['DateTime']['input']>
  trainingPeriod?: InputMaybe<Scalars['Period']['input']>
  trainingStart?: InputMaybe<Scalars['DateTime']['input']>
  typeId?: InputMaybe<Scalars['ID']['input']>
}

export type GqlUpdateModelPayload = {
  __typename?: 'UpdateModelPayload'
  model: GqlModel
}

export type GqlUpdateModelThresholdsInput = {
  modelId: Scalars['ID']['input']
  shortAnomalyThreshold?: InputMaybe<Scalars['Float']['input']>
  spikeAnomalyThreshold?: InputMaybe<Scalars['Float']['input']>
}

export type GqlUpdateModelThresholdsPayload = {
  __typename?: 'UpdateModelThresholdsPayload'
  model: GqlModel
}

export type GqlUpdateOpcConnectionInput = {
  credentials?: InputMaybe<GqlOpcCredentialsInput>
  endpoint: Scalars['Uri']['input']
  ignoreServerEndpoint: Scalars['Boolean']['input']
  name: Scalars['String']['input']
  opcConnectionId: Scalars['ID']['input']
  securityMode?: InputMaybe<GqlOpcSecurityMode>
  securityPolicy?: InputMaybe<GqlOpcSecurityPolicy>
  x509Thumbprint?: InputMaybe<Scalars['String']['input']>
}

export type GqlUpdateOpcConnectionPayload = {
  __typename?: 'UpdateOpcConnectionPayload'
  connection: GqlOpcConnection
}

export type GqlUpdateTagInput = {
  description?: InputMaybe<Scalars['String']['input']>
  displayName?: InputMaybe<Scalars['String']['input']>
  engUnit?: InputMaybe<Scalars['String']['input']>
  tagId: Scalars['ID']['input']
}

export type GqlUpdateTagPayload = {
  __typename?: 'UpdateTagPayload'
  tag: GqlTag
}

export type GqlUpdateTrainedModelInput = {
  id: Scalars['ID']['input']
  loss?: InputMaybe<Scalars['Float']['input']>
  mae?: InputMaybe<Scalars['Float']['input']>
  mape?: InputMaybe<Scalars['Float']['input']>
  meanLabel?: InputMaybe<Scalars['Float']['input']>
  meanPred?: InputMaybe<Scalars['Float']['input']>
  rSquare?: InputMaybe<Scalars['Float']['input']>
  valLoss?: InputMaybe<Scalars['Float']['input']>
  valMae?: InputMaybe<Scalars['Float']['input']>
  valMape?: InputMaybe<Scalars['Float']['input']>
  valMeanLabel?: InputMaybe<Scalars['Float']['input']>
  valMeanPred?: InputMaybe<Scalars['Float']['input']>
  valRSquare?: InputMaybe<Scalars['Float']['input']>
}

export type GqlUpdateTrainedModelPayload = {
  __typename?: 'UpdateTrainedModelPayload'
  trainedModel: GqlTrainedModel
}

export type GqlUpdateTrainingTaskInput = {
  id: Scalars['ID']['input']
  queueId?: InputMaybe<Scalars['String']['input']>
  successful?: InputMaybe<Scalars['Boolean']['input']>
  taskId?: InputMaybe<Scalars['String']['input']>
}

export type GqlUpdateTrainingTaskPayload = {
  __typename?: 'UpdateTrainingTaskPayload'
  trainingTask: GqlTrainingTask
}

export type GqlUpdateTrendViewInput = {
  data: Scalars['String']['input']
  name: Scalars['String']['input']
  shared: Scalars['Boolean']['input']
  trendViewId: Scalars['ID']['input']
}

export type GqlUpdateTrendViewPayload = {
  __typename?: 'UpdateTrendViewPayload'
  trendView: GqlTrendView
}

export type GqlUpdateUserConsentInput = {
  cookieConsent: Scalars['Boolean']['input']
  dataCollectionConsent: Scalars['Boolean']['input']
}

export type GqlUpdateUserConsentPayload = {
  __typename?: 'UpdateUserConsentPayload'
  user: GqlUser
}

export type GqlUpdateUserDefaultFactoryInput = {
  factoryId: Scalars['ID']['input']
}

export type GqlUpdateUserDefaultFactoryPayload = {
  __typename?: 'UpdateUserDefaultFactoryPayload'
  user: GqlUser
}

export type GqlUpdateUserNameInput = {
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
}

export type GqlUpdateUserNamePayload = {
  __typename?: 'UpdateUserNamePayload'
  user: GqlUser
}

export type GqlUser = GqlNode & {
  __typename?: 'User'
  blocked: Scalars['Boolean']['output']
  cookieConsent: Scalars['Boolean']['output']
  createdAt?: Maybe<Scalars['DateTime']['output']>
  createdBy?: Maybe<GqlUser>
  customer?: Maybe<GqlCustomer>
  customers?: Maybe<GqlUserCustomerConnection>
  dataCollectionConsent: Scalars['Boolean']['output']
  defaultFactoryId?: Maybe<Scalars['ID']['output']>
  email?: Maybe<Scalars['String']['output']>
  factories: Array<GqlFactory>
  hasAppAccess: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  invitations?: Maybe<GqlFactoryInvitationConnection>
  name: Scalars['String']['output']
  status: GqlUserStatus
}

export type GqlUserCustomersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
}

export type GqlUserInvitationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
}

export type GqlUserConnection = {
  __typename?: 'UserConnection'
  edges?: Maybe<Array<Maybe<GqlUserEdge>>>
  items?: Maybe<Array<Maybe<GqlUser>>>
  pageInfo: GqlPageInfo
  totalCount?: Maybe<Scalars['Int']['output']>
}

export type GqlUserCustomerConnection = {
  __typename?: 'UserCustomerConnection'
  edges?: Maybe<Array<Maybe<GqlUserCustomerEdge>>>
  items?: Maybe<Array<Maybe<GqlCustomer>>>
  pageInfo: GqlPageInfo
  totalCount?: Maybe<Scalars['Int']['output']>
}

export type GqlUserCustomerEdge = {
  __typename?: 'UserCustomerEdge'
  cursor: Scalars['String']['output']
  node?: Maybe<GqlCustomer>
  role: GqlCustomerRole
}

export type GqlUserEdge = {
  __typename?: 'UserEdge'
  cursor: Scalars['String']['output']
  node?: Maybe<GqlUser>
}

export type GqlUserStatus = 'DISABLED' | 'ENABLED'

export type GqlValidateOpcConnectionInput = {
  credentials?: InputMaybe<GqlOpcCredentialsInput>
  endpoint: Scalars['Uri']['input']
  ignoreServerEndpoint: Scalars['Boolean']['input']
  securityMode?: InputMaybe<GqlOpcSecurityMode>
  securityPolicy?: InputMaybe<GqlOpcSecurityPolicy>
  x509Thumbprint?: InputMaybe<Scalars['String']['input']>
}

export type GqlValidateOpcConnectionResponse = {
  __typename?: 'ValidateOpcConnectionResponse'
  error?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type GqlVariableOpcNode = GqlOpcNode & {
  __typename?: 'VariableOpcNode'
  browseName: Scalars['String']['output']
  dataType?: Maybe<Scalars['String']['output']>
  description: Scalars['String']['output']
  displayName: Scalars['String']['output']
  nodeId: Scalars['String']['output']
}

export type GqlMeFragment = {
  __typename?: 'User'
  id: string
  name: string
  email?: string | null
  dataCollectionConsent: boolean
  cookieConsent: boolean
  defaultFactoryId?: string | null
  hasAppAccess: boolean
  factories: Array<{
    __typename?: 'Factory'
    id: string
    name: string
    viewerRole?: GqlFactoryRole | null
    customer: { __typename?: 'Customer'; id: string; name: string }
  }>
  customers?: {
    __typename?: 'UserCustomerConnection'
    items?: Array<{
      __typename?: 'Customer'
      name: string
      id: string
      viewerRole?: GqlCustomerRole | null
      userCount: number
      factoryCount: number
    } | null> | null
  } | null
  customer?: { __typename?: 'Customer'; id: string } | null
  invitations?: {
    __typename?: 'FactoryInvitationConnection'
    items?: Array<{
      __typename?: 'FactoryInvitation'
      id: string
      role: GqlFactoryRole
      createdAt: string
      validUntil: string
      completedAt?: string | null
      state: GqlInvitationState
      inviter: { __typename?: 'User'; id: string; name: string }
      factory: {
        __typename?: 'Factory'
        id: string
        name: string
        customer: { __typename: 'Customer'; id: string; name: string }
      }
    } | null> | null
  } | null
}

export type GqlMeQueryVariables = Exact<{ [key: string]: never }>

export type GqlMeQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    name: string
    email?: string | null
    dataCollectionConsent: boolean
    cookieConsent: boolean
    defaultFactoryId?: string | null
    hasAppAccess: boolean
    factories: Array<{
      __typename?: 'Factory'
      id: string
      name: string
      viewerRole?: GqlFactoryRole | null
      customer: { __typename?: 'Customer'; id: string; name: string }
    }>
    customers?: {
      __typename?: 'UserCustomerConnection'
      items?: Array<{
        __typename?: 'Customer'
        name: string
        id: string
        viewerRole?: GqlCustomerRole | null
        userCount: number
        factoryCount: number
      } | null> | null
    } | null
    customer?: { __typename?: 'Customer'; id: string } | null
    invitations?: {
      __typename?: 'FactoryInvitationConnection'
      items?: Array<{
        __typename?: 'FactoryInvitation'
        id: string
        role: GqlFactoryRole
        createdAt: string
        validUntil: string
        completedAt?: string | null
        state: GqlInvitationState
        inviter: { __typename?: 'User'; id: string; name: string }
        factory: {
          __typename?: 'Factory'
          id: string
          name: string
          customer: { __typename: 'Customer'; id: string; name: string }
        }
      } | null> | null
    } | null
  }
}

export type GqlInvitationBaseFragment = {
  __typename?: 'FactoryInvitation'
  id: string
  role: GqlFactoryRole
  createdAt: string
  validUntil: string
  completedAt?: string | null
  state: GqlInvitationState
  inviter: { __typename?: 'User'; id: string; name: string }
  factory: {
    __typename?: 'Factory'
    id: string
    name: string
    customer: { __typename: 'Customer'; id: string; name: string }
  }
}

export type GqlTsDataFragment = {
  __typename: 'TimeSeriesData'
  timestamp: string
  value: number
  minValue: number
  maxValue: number
}

export type GqlFetchModelAnomalyScoreQueryVariables = Exact<{
  id: Scalars['ID']['input']
  from: Scalars['DateTime']['input']
  to: Scalars['DateTime']['input']
  interval: Scalars['Int']['input']
  errorScoreKind: GqlErrorScoreKind
  transform: GqlTransform
}>

export type GqlFetchModelAnomalyScoreQuery = {
  __typename?: 'Query'
  node?:
    | { __typename: 'Agent'; id: string }
    | { __typename: 'Anomaly'; id: string }
    | { __typename: 'AnomalyComment'; id: string }
    | {
        __typename: 'AnomalyModel'
        id: string
        errorScore2: {
          __typename: 'TimeSeries'
          data: Array<{
            __typename: 'TimeSeriesData'
            timestamp: string
            value: number
            minValue: number
            maxValue: number
          }>
        }
      }
    | { __typename: 'Asset'; id: string }
    | { __typename: 'Customer'; id: string }
    | { __typename: 'Factory'; id: string }
    | { __typename: 'FactoryInvitation'; id: string }
    | { __typename: 'ForecastModel'; id: string }
    | { __typename: 'HierarchyAsset'; id: string }
    | { __typename: 'HierarchyTag'; id: string }
    | { __typename: 'ModelMethod'; id: string }
    | { __typename: 'ModelType'; id: string }
    | { __typename: 'OpcConnection'; id: string }
    | { __typename: 'OpcStream'; id: string }
    | { __typename: 'Tag'; id: string }
    | { __typename: 'TrainedModel'; id: string }
    | { __typename: 'TrainingTask'; id: string }
    | { __typename: 'TrendView'; id: string }
    | { __typename: 'User'; id: string }
    | null
}

export type GqlProcessDataQueryVariables = Exact<{
  factory: Scalars['ID']['input']
  tagName: Scalars['String']['input']
  from: Scalars['DateTime']['input']
  to: Scalars['DateTime']['input']
  interval: Scalars['Int']['input']
}>

export type GqlProcessDataQuery = {
  __typename?: 'Query'
  factory?: {
    __typename: 'Factory'
    tagByName?: {
      __typename: 'Tag'
      processData?: {
        __typename: 'TimeSeries'
        data: Array<{
          __typename: 'TimeSeriesData'
          timestamp: string
          value: number
          minValue: number
          maxValue: number
        }>
      } | null
    } | null
  } | null
}

export type GqlPredictionsQueryVariables = Exact<{
  id: Scalars['ID']['input']
  from: Scalars['DateTime']['input']
  to: Scalars['DateTime']['input']
  interval: Scalars['Int']['input']
}>

export type GqlPredictionsQuery = {
  __typename?: 'Query'
  node?:
    | { __typename: 'Agent'; id: string }
    | { __typename: 'Anomaly'; id: string }
    | { __typename: 'AnomalyComment'; id: string }
    | {
        __typename: 'AnomalyModel'
        id: string
        prediction: {
          __typename: 'TimeSeries'
          data: Array<{
            __typename: 'TimeSeriesData'
            timestamp: string
            value: number
            minValue: number
            maxValue: number
          }>
        }
      }
    | { __typename: 'Asset'; id: string }
    | { __typename: 'Customer'; id: string }
    | { __typename: 'Factory'; id: string }
    | { __typename: 'FactoryInvitation'; id: string }
    | { __typename: 'ForecastModel'; id: string }
    | { __typename: 'HierarchyAsset'; id: string }
    | { __typename: 'HierarchyTag'; id: string }
    | { __typename: 'ModelMethod'; id: string }
    | { __typename: 'ModelType'; id: string }
    | { __typename: 'OpcConnection'; id: string }
    | { __typename: 'OpcStream'; id: string }
    | { __typename: 'Tag'; id: string }
    | { __typename: 'TrainedModel'; id: string }
    | { __typename: 'TrainingTask'; id: string }
    | { __typename: 'TrendView'; id: string }
    | { __typename: 'User'; id: string }
    | null
}

export type GqlModelTypeFragment = {
  __typename: 'ModelType'
  id: string
  name: string
  methods: Array<{ __typename: 'ModelMethod'; id: string; name: string }>
  defaultMethod: { __typename: 'ModelMethod'; id: string; name: string }
}

export type GqlFetchModelTypesQueryVariables = Exact<{ [key: string]: never }>

export type GqlFetchModelTypesQuery = {
  __typename?: 'Query'
  modelTypes: Array<{
    __typename: 'ModelType'
    id: string
    name: string
    methods: Array<{ __typename: 'ModelMethod'; id: string; name: string }>
    defaultMethod: { __typename: 'ModelMethod'; id: string; name: string }
  }>
}

export type GqlModelTagBaseFragment = {
  __typename: 'Tag'
  id: string
  tagName: string
  engUnit?: string | null
  description?: string | null
  displayName?: string | null
  availableDataTimeRange?: {
    __typename: 'ProcessDataEventTimeRange'
    min: string
    max: string
  } | null
  metadata?: {
    __typename?: 'TagMetadata'
    isBad?: boolean | null
    label?: string | null
  } | null
}

export type GqlModelBase_AnomalyModel_Fragment = {
  __typename: 'AnomalyModel'
  viewerHasStarred: boolean
  id: string
  name: string
  description: string
  state: GqlModelState
  created: string
  trainingStart?: string | null
  trainingEnd?: string | null
  trainingPeriod?: string | null
  errorMessage?: string | null
  type: { __typename: 'ModelType'; id: string; name: string }
  method: { __typename: 'ModelMethod'; id: string; name: string }
  tag: {
    __typename: 'Tag'
    id: string
    tagName: string
    engUnit?: string | null
    description?: string | null
    displayName?: string | null
    availableDataTimeRange?: {
      __typename: 'ProcessDataEventTimeRange'
      min: string
      max: string
    } | null
    metadata?: {
      __typename?: 'TagMetadata'
      isBad?: boolean | null
      label?: string | null
    } | null
  }
  inputTags: Array<{
    __typename: 'Tag'
    id: string
    tagName: string
    engUnit?: string | null
    description?: string | null
    displayName?: string | null
    availableDataTimeRange?: {
      __typename: 'ProcessDataEventTimeRange'
      min: string
      max: string
    } | null
    metadata?: {
      __typename?: 'TagMetadata'
      isBad?: boolean | null
      label?: string | null
    } | null
  }>
  activeTrainedModel?: {
    __typename: 'TrainedModel'
    valMae?: number | null
    valRSquare?: number | null
    anomalyCount?: number | null
    dataEnd: string
    dataStart: string
  } | null
  createdBy?: { __typename: 'User'; id: string; name: string } | null
}

export type GqlModelBase_ForecastModel_Fragment = {
  __typename: 'ForecastModel'
  viewerHasStarred: boolean
  id: string
  name: string
  description: string
  state: GqlModelState
  created: string
  trainingStart?: string | null
  trainingEnd?: string | null
  trainingPeriod?: string | null
  errorMessage?: string | null
  type: { __typename: 'ModelType'; id: string; name: string }
  method: { __typename: 'ModelMethod'; id: string; name: string }
  tag: {
    __typename: 'Tag'
    id: string
    tagName: string
    engUnit?: string | null
    description?: string | null
    displayName?: string | null
    availableDataTimeRange?: {
      __typename: 'ProcessDataEventTimeRange'
      min: string
      max: string
    } | null
    metadata?: {
      __typename?: 'TagMetadata'
      isBad?: boolean | null
      label?: string | null
    } | null
  }
  inputTags: Array<{
    __typename: 'Tag'
    id: string
    tagName: string
    engUnit?: string | null
    description?: string | null
    displayName?: string | null
    availableDataTimeRange?: {
      __typename: 'ProcessDataEventTimeRange'
      min: string
      max: string
    } | null
    metadata?: {
      __typename?: 'TagMetadata'
      isBad?: boolean | null
      label?: string | null
    } | null
  }>
  activeTrainedModel?: {
    __typename: 'TrainedModel'
    valMae?: number | null
    valRSquare?: number | null
    anomalyCount?: number | null
    dataEnd: string
    dataStart: string
  } | null
  createdBy?: { __typename: 'User'; id: string; name: string } | null
}

export type GqlModelBaseFragment =
  | GqlModelBase_AnomalyModel_Fragment
  | GqlModelBase_ForecastModel_Fragment

export type GqlFetchModelsQueryVariables = Exact<{
  factory: Scalars['ID']['input']
}>

export type GqlFetchModelsQuery = {
  __typename?: 'Query'
  factory?: {
    __typename: 'Factory'
    models?: {
      __typename: 'ModelInterfaceConnection'
      items?: Array<
        | {
            __typename: 'AnomalyModel'
            viewerHasStarred: boolean
            id: string
            name: string
            description: string
            state: GqlModelState
            created: string
            trainingStart?: string | null
            trainingEnd?: string | null
            trainingPeriod?: string | null
            errorMessage?: string | null
            type: { __typename: 'ModelType'; id: string; name: string }
            method: { __typename: 'ModelMethod'; id: string; name: string }
            tag: {
              __typename: 'Tag'
              id: string
              tagName: string
              engUnit?: string | null
              description?: string | null
              displayName?: string | null
              availableDataTimeRange?: {
                __typename: 'ProcessDataEventTimeRange'
                min: string
                max: string
              } | null
              metadata?: {
                __typename?: 'TagMetadata'
                isBad?: boolean | null
                label?: string | null
              } | null
            }
            inputTags: Array<{
              __typename: 'Tag'
              id: string
              tagName: string
              engUnit?: string | null
              description?: string | null
              displayName?: string | null
              availableDataTimeRange?: {
                __typename: 'ProcessDataEventTimeRange'
                min: string
                max: string
              } | null
              metadata?: {
                __typename?: 'TagMetadata'
                isBad?: boolean | null
                label?: string | null
              } | null
            }>
            activeTrainedModel?: {
              __typename: 'TrainedModel'
              valMae?: number | null
              valRSquare?: number | null
              anomalyCount?: number | null
              dataEnd: string
              dataStart: string
            } | null
            createdBy?: { __typename: 'User'; id: string; name: string } | null
          }
        | {
            __typename: 'ForecastModel'
            viewerHasStarred: boolean
            id: string
            name: string
            description: string
            state: GqlModelState
            created: string
            trainingStart?: string | null
            trainingEnd?: string | null
            trainingPeriod?: string | null
            errorMessage?: string | null
            type: { __typename: 'ModelType'; id: string; name: string }
            method: { __typename: 'ModelMethod'; id: string; name: string }
            tag: {
              __typename: 'Tag'
              id: string
              tagName: string
              engUnit?: string | null
              description?: string | null
              displayName?: string | null
              availableDataTimeRange?: {
                __typename: 'ProcessDataEventTimeRange'
                min: string
                max: string
              } | null
              metadata?: {
                __typename?: 'TagMetadata'
                isBad?: boolean | null
                label?: string | null
              } | null
            }
            inputTags: Array<{
              __typename: 'Tag'
              id: string
              tagName: string
              engUnit?: string | null
              description?: string | null
              displayName?: string | null
              availableDataTimeRange?: {
                __typename: 'ProcessDataEventTimeRange'
                min: string
                max: string
              } | null
              metadata?: {
                __typename?: 'TagMetadata'
                isBad?: boolean | null
                label?: string | null
              } | null
            }>
            activeTrainedModel?: {
              __typename: 'TrainedModel'
              valMae?: number | null
              valRSquare?: number | null
              anomalyCount?: number | null
              dataEnd: string
              dataStart: string
            } | null
            createdBy?: { __typename: 'User'; id: string; name: string } | null
          }
        | null
      > | null
    } | null
  } | null
}

export type GqlModelAnomalyCommentFragment = {
  __typename?: 'AnomalyComment'
  at: string
  by: string
  comment: string
}

export type GqlModelAnomalyFragment = {
  __typename?: 'Anomaly'
  id: string
  restId: number
  start: string
  end?: string | null
  score?: number | null
  state: GqlAnomalyState
  inProgress: boolean
  comments: Array<{
    __typename?: 'AnomalyComment'
    at: string
    by: string
    comment: string
  }>
}

export type GqlAnomalyConditionsFragment = {
  __typename?: 'AnomalyCondition'
  threshold: number
  thresholdKind: GqlThresholdKind
  errorScore: {
    __typename?: 'ErrorScore'
    kind: GqlErrorScoreKind
    transform: GqlTransform
  }
}

export type GqlFetchModel_AnomalyModel_Fragment = {
  __typename: 'AnomalyModel'
  anomalyGenerationFilter?: string | null
  initialInferenceStart?: string | null
  initialInferenceEnd?: string | null
  viewerHasStarred: boolean
  id: string
  name: string
  description: string
  state: GqlModelState
  created: string
  trainingStart?: string | null
  trainingEnd?: string | null
  trainingPeriod?: string | null
  errorMessage?: string | null
  availableErrorScores: Array<{
    __typename?: 'ErrorScore'
    kind: GqlErrorScoreKind
    transform: GqlTransform
  }>
  anomalyConditions: Array<{
    __typename?: 'AnomalyCondition'
    threshold: number
    thresholdKind: GqlThresholdKind
    errorScore: {
      __typename?: 'ErrorScore'
      kind: GqlErrorScoreKind
      transform: GqlTransform
    }
  }>
  anomalies: Array<{
    __typename?: 'Anomaly'
    id: string
    restId: number
    start: string
    end?: string | null
    score?: number | null
    state: GqlAnomalyState
    inProgress: boolean
    comments: Array<{
      __typename?: 'AnomalyComment'
      at: string
      by: string
      comment: string
    }>
  }>
  type: { __typename: 'ModelType'; id: string; name: string }
  method: { __typename: 'ModelMethod'; id: string; name: string }
  tag: {
    __typename: 'Tag'
    id: string
    tagName: string
    engUnit?: string | null
    description?: string | null
    displayName?: string | null
    availableDataTimeRange?: {
      __typename: 'ProcessDataEventTimeRange'
      min: string
      max: string
    } | null
    metadata?: {
      __typename?: 'TagMetadata'
      isBad?: boolean | null
      label?: string | null
    } | null
  }
  inputTags: Array<{
    __typename: 'Tag'
    id: string
    tagName: string
    engUnit?: string | null
    description?: string | null
    displayName?: string | null
    availableDataTimeRange?: {
      __typename: 'ProcessDataEventTimeRange'
      min: string
      max: string
    } | null
    metadata?: {
      __typename?: 'TagMetadata'
      isBad?: boolean | null
      label?: string | null
    } | null
  }>
  activeTrainedModel?: {
    __typename: 'TrainedModel'
    valMae?: number | null
    valRSquare?: number | null
    anomalyCount?: number | null
    dataEnd: string
    dataStart: string
  } | null
  createdBy?: { __typename: 'User'; id: string; name: string } | null
}

export type GqlFetchModel_ForecastModel_Fragment = {
  __typename: 'ForecastModel'
  initialInferenceStart?: string | null
  initialInferenceEnd?: string | null
  viewerHasStarred: boolean
  id: string
  name: string
  description: string
  state: GqlModelState
  created: string
  trainingStart?: string | null
  trainingEnd?: string | null
  trainingPeriod?: string | null
  errorMessage?: string | null
  type: { __typename: 'ModelType'; id: string; name: string }
  method: { __typename: 'ModelMethod'; id: string; name: string }
  tag: {
    __typename: 'Tag'
    id: string
    tagName: string
    engUnit?: string | null
    description?: string | null
    displayName?: string | null
    availableDataTimeRange?: {
      __typename: 'ProcessDataEventTimeRange'
      min: string
      max: string
    } | null
    metadata?: {
      __typename?: 'TagMetadata'
      isBad?: boolean | null
      label?: string | null
    } | null
  }
  inputTags: Array<{
    __typename: 'Tag'
    id: string
    tagName: string
    engUnit?: string | null
    description?: string | null
    displayName?: string | null
    availableDataTimeRange?: {
      __typename: 'ProcessDataEventTimeRange'
      min: string
      max: string
    } | null
    metadata?: {
      __typename?: 'TagMetadata'
      isBad?: boolean | null
      label?: string | null
    } | null
  }>
  activeTrainedModel?: {
    __typename: 'TrainedModel'
    valMae?: number | null
    valRSquare?: number | null
    anomalyCount?: number | null
    dataEnd: string
    dataStart: string
  } | null
  createdBy?: { __typename: 'User'; id: string; name: string } | null
}

export type GqlFetchModelFragment =
  | GqlFetchModel_AnomalyModel_Fragment
  | GqlFetchModel_ForecastModel_Fragment

export type GqlFetchModelQueryVariables = Exact<{
  modelId: Scalars['ID']['input']
}>

export type GqlFetchModelQuery = {
  __typename?: 'Query'
  node?:
    | { __typename?: 'Agent' }
    | { __typename?: 'Anomaly' }
    | { __typename?: 'AnomalyComment' }
    | {
        __typename: 'AnomalyModel'
        anomalyGenerationFilter?: string | null
        initialInferenceStart?: string | null
        initialInferenceEnd?: string | null
        viewerHasStarred: boolean
        id: string
        name: string
        description: string
        state: GqlModelState
        created: string
        trainingStart?: string | null
        trainingEnd?: string | null
        trainingPeriod?: string | null
        errorMessage?: string | null
        availableErrorScores: Array<{
          __typename?: 'ErrorScore'
          kind: GqlErrorScoreKind
          transform: GqlTransform
        }>
        anomalyConditions: Array<{
          __typename?: 'AnomalyCondition'
          threshold: number
          thresholdKind: GqlThresholdKind
          errorScore: {
            __typename?: 'ErrorScore'
            kind: GqlErrorScoreKind
            transform: GqlTransform
          }
        }>
        anomalies: Array<{
          __typename?: 'Anomaly'
          id: string
          restId: number
          start: string
          end?: string | null
          score?: number | null
          state: GqlAnomalyState
          inProgress: boolean
          comments: Array<{
            __typename?: 'AnomalyComment'
            at: string
            by: string
            comment: string
          }>
        }>
        type: { __typename: 'ModelType'; id: string; name: string }
        method: { __typename: 'ModelMethod'; id: string; name: string }
        tag: {
          __typename: 'Tag'
          id: string
          tagName: string
          engUnit?: string | null
          description?: string | null
          displayName?: string | null
          availableDataTimeRange?: {
            __typename: 'ProcessDataEventTimeRange'
            min: string
            max: string
          } | null
          metadata?: {
            __typename?: 'TagMetadata'
            isBad?: boolean | null
            label?: string | null
          } | null
        }
        inputTags: Array<{
          __typename: 'Tag'
          id: string
          tagName: string
          engUnit?: string | null
          description?: string | null
          displayName?: string | null
          availableDataTimeRange?: {
            __typename: 'ProcessDataEventTimeRange'
            min: string
            max: string
          } | null
          metadata?: {
            __typename?: 'TagMetadata'
            isBad?: boolean | null
            label?: string | null
          } | null
        }>
        activeTrainedModel?: {
          __typename: 'TrainedModel'
          valMae?: number | null
          valRSquare?: number | null
          anomalyCount?: number | null
          dataEnd: string
          dataStart: string
        } | null
        createdBy?: { __typename: 'User'; id: string; name: string } | null
      }
    | { __typename?: 'Asset' }
    | { __typename?: 'Customer' }
    | { __typename?: 'Factory' }
    | { __typename?: 'FactoryInvitation' }
    | {
        __typename: 'ForecastModel'
        initialInferenceStart?: string | null
        initialInferenceEnd?: string | null
        viewerHasStarred: boolean
        id: string
        name: string
        description: string
        state: GqlModelState
        created: string
        trainingStart?: string | null
        trainingEnd?: string | null
        trainingPeriod?: string | null
        errorMessage?: string | null
        type: { __typename: 'ModelType'; id: string; name: string }
        method: { __typename: 'ModelMethod'; id: string; name: string }
        tag: {
          __typename: 'Tag'
          id: string
          tagName: string
          engUnit?: string | null
          description?: string | null
          displayName?: string | null
          availableDataTimeRange?: {
            __typename: 'ProcessDataEventTimeRange'
            min: string
            max: string
          } | null
          metadata?: {
            __typename?: 'TagMetadata'
            isBad?: boolean | null
            label?: string | null
          } | null
        }
        inputTags: Array<{
          __typename: 'Tag'
          id: string
          tagName: string
          engUnit?: string | null
          description?: string | null
          displayName?: string | null
          availableDataTimeRange?: {
            __typename: 'ProcessDataEventTimeRange'
            min: string
            max: string
          } | null
          metadata?: {
            __typename?: 'TagMetadata'
            isBad?: boolean | null
            label?: string | null
          } | null
        }>
        activeTrainedModel?: {
          __typename: 'TrainedModel'
          valMae?: number | null
          valRSquare?: number | null
          anomalyCount?: number | null
          dataEnd: string
          dataStart: string
        } | null
        createdBy?: { __typename: 'User'; id: string; name: string } | null
      }
    | { __typename?: 'HierarchyAsset' }
    | { __typename?: 'HierarchyTag' }
    | { __typename?: 'ModelMethod' }
    | { __typename?: 'ModelType' }
    | { __typename?: 'OpcConnection' }
    | { __typename?: 'OpcStream' }
    | { __typename?: 'Tag' }
    | { __typename?: 'TrainedModel' }
    | { __typename?: 'TrainingTask' }
    | { __typename?: 'TrendView' }
    | { __typename?: 'User' }
    | null
}

export type GqlModelIdState_AnomalyModel_Fragment = {
  __typename: 'AnomalyModel'
  id: string
  state: GqlModelState
}

export type GqlModelIdState_ForecastModel_Fragment = {
  __typename: 'ForecastModel'
  id: string
  state: GqlModelState
}

export type GqlModelIdStateFragment =
  | GqlModelIdState_AnomalyModel_Fragment
  | GqlModelIdState_ForecastModel_Fragment

export type GqlArchiveModelMutationVariables = Exact<{
  input: GqlArchiveModelInput
}>

export type GqlArchiveModelMutation = {
  __typename?: 'Mutation'
  archiveModel?: {
    __typename: 'ArchiveModelPayload'
    model:
      | { __typename: 'AnomalyModel'; id: string; state: GqlModelState }
      | { __typename: 'ForecastModel'; id: string; state: GqlModelState }
  } | null
}

export type GqlDeleteModelMutationVariables = Exact<{
  input: GqlDeleteModelInput
}>

export type GqlDeleteModelMutation = {
  __typename?: 'Mutation'
  deleteModel?: {
    __typename: 'DeleteModelPayload'
    model:
      | { __typename: 'AnomalyModel'; id: string; state: GqlModelState }
      | { __typename: 'ForecastModel'; id: string; state: GqlModelState }
  } | null
}

export type GqlStartModelMutationVariables = Exact<{
  input: GqlStartModelInput
}>

export type GqlStartModelMutation = {
  __typename?: 'Mutation'
  startModel?: {
    __typename: 'StartModelPayload'
    model:
      | { __typename: 'AnomalyModel'; id: string; state: GqlModelState }
      | { __typename: 'ForecastModel'; id: string; state: GqlModelState }
  } | null
}

export type GqlStopModelMutationVariables = Exact<{
  input: GqlStopModelInput
}>

export type GqlStopModelMutation = {
  __typename?: 'Mutation'
  stopModel?: {
    __typename: 'StopModelPayload'
    model:
      | { __typename: 'AnomalyModel'; id: string; state: GqlModelState }
      | { __typename: 'ForecastModel'; id: string; state: GqlModelState }
  } | null
}

export type GqlActivateModelMutationVariables = Exact<{
  input: GqlActivateModelInput
}>

export type GqlActivateModelMutation = {
  __typename?: 'Mutation'
  activateModel?: {
    __typename: 'ActivateModelPayload'
    model:
      | { __typename: 'AnomalyModel'; id: string; state: GqlModelState }
      | { __typename: 'ForecastModel'; id: string; state: GqlModelState }
  } | null
}

export type GqlRunAnomalyDetectionMutationVariables = Exact<{
  input: GqlRunAnomalyDetectionInput
}>

export type GqlRunAnomalyDetectionMutation = {
  __typename?: 'Mutation'
  runAnomalyDetection?: {
    __typename: 'RunAnomalyDetectionPayload'
    model:
      | { __typename: 'AnomalyModel'; id: string; state: GqlModelState }
      | { __typename: 'ForecastModel'; id: string; state: GqlModelState }
  } | null
}

export type GqlUpdateModelMutationVariables = Exact<{
  input: GqlUpdateModelInput
}>

export type GqlUpdateModelMutation = {
  __typename?: 'Mutation'
  updateModel?: {
    __typename: 'UpdateModelPayload'
    model:
      | {
          __typename: 'AnomalyModel'
          viewerHasStarred: boolean
          id: string
          name: string
          description: string
          state: GqlModelState
          created: string
          trainingStart?: string | null
          trainingEnd?: string | null
          trainingPeriod?: string | null
          errorMessage?: string | null
          type: { __typename: 'ModelType'; id: string; name: string }
          method: { __typename: 'ModelMethod'; id: string; name: string }
          tag: {
            __typename: 'Tag'
            id: string
            tagName: string
            engUnit?: string | null
            description?: string | null
            displayName?: string | null
            availableDataTimeRange?: {
              __typename: 'ProcessDataEventTimeRange'
              min: string
              max: string
            } | null
            metadata?: {
              __typename?: 'TagMetadata'
              isBad?: boolean | null
              label?: string | null
            } | null
          }
          inputTags: Array<{
            __typename: 'Tag'
            id: string
            tagName: string
            engUnit?: string | null
            description?: string | null
            displayName?: string | null
            availableDataTimeRange?: {
              __typename: 'ProcessDataEventTimeRange'
              min: string
              max: string
            } | null
            metadata?: {
              __typename?: 'TagMetadata'
              isBad?: boolean | null
              label?: string | null
            } | null
          }>
          activeTrainedModel?: {
            __typename: 'TrainedModel'
            valMae?: number | null
            valRSquare?: number | null
            anomalyCount?: number | null
            dataEnd: string
            dataStart: string
          } | null
          createdBy?: { __typename: 'User'; id: string; name: string } | null
        }
      | {
          __typename: 'ForecastModel'
          viewerHasStarred: boolean
          id: string
          name: string
          description: string
          state: GqlModelState
          created: string
          trainingStart?: string | null
          trainingEnd?: string | null
          trainingPeriod?: string | null
          errorMessage?: string | null
          type: { __typename: 'ModelType'; id: string; name: string }
          method: { __typename: 'ModelMethod'; id: string; name: string }
          tag: {
            __typename: 'Tag'
            id: string
            tagName: string
            engUnit?: string | null
            description?: string | null
            displayName?: string | null
            availableDataTimeRange?: {
              __typename: 'ProcessDataEventTimeRange'
              min: string
              max: string
            } | null
            metadata?: {
              __typename?: 'TagMetadata'
              isBad?: boolean | null
              label?: string | null
            } | null
          }
          inputTags: Array<{
            __typename: 'Tag'
            id: string
            tagName: string
            engUnit?: string | null
            description?: string | null
            displayName?: string | null
            availableDataTimeRange?: {
              __typename: 'ProcessDataEventTimeRange'
              min: string
              max: string
            } | null
            metadata?: {
              __typename?: 'TagMetadata'
              isBad?: boolean | null
              label?: string | null
            } | null
          }>
          activeTrainedModel?: {
            __typename: 'TrainedModel'
            valMae?: number | null
            valRSquare?: number | null
            anomalyCount?: number | null
            dataEnd: string
            dataStart: string
          } | null
          createdBy?: { __typename: 'User'; id: string; name: string } | null
        }
  } | null
}

export type GqlRemoveInputTagsFromModelMutationVariables = Exact<{
  input: GqlRemoveInputTagsFromModelInput
}>

export type GqlRemoveInputTagsFromModelMutation = {
  __typename?: 'Mutation'
  removeInputTagsFromModel?: {
    __typename: 'RemoveInputTagsFromModelPayload'
    model:
      | {
          __typename: 'AnomalyModel'
          viewerHasStarred: boolean
          id: string
          name: string
          description: string
          state: GqlModelState
          created: string
          trainingStart?: string | null
          trainingEnd?: string | null
          trainingPeriod?: string | null
          errorMessage?: string | null
          type: { __typename: 'ModelType'; id: string; name: string }
          method: { __typename: 'ModelMethod'; id: string; name: string }
          tag: {
            __typename: 'Tag'
            id: string
            tagName: string
            engUnit?: string | null
            description?: string | null
            displayName?: string | null
            availableDataTimeRange?: {
              __typename: 'ProcessDataEventTimeRange'
              min: string
              max: string
            } | null
            metadata?: {
              __typename?: 'TagMetadata'
              isBad?: boolean | null
              label?: string | null
            } | null
          }
          inputTags: Array<{
            __typename: 'Tag'
            id: string
            tagName: string
            engUnit?: string | null
            description?: string | null
            displayName?: string | null
            availableDataTimeRange?: {
              __typename: 'ProcessDataEventTimeRange'
              min: string
              max: string
            } | null
            metadata?: {
              __typename?: 'TagMetadata'
              isBad?: boolean | null
              label?: string | null
            } | null
          }>
          activeTrainedModel?: {
            __typename: 'TrainedModel'
            valMae?: number | null
            valRSquare?: number | null
            anomalyCount?: number | null
            dataEnd: string
            dataStart: string
          } | null
          createdBy?: { __typename: 'User'; id: string; name: string } | null
        }
      | {
          __typename: 'ForecastModel'
          viewerHasStarred: boolean
          id: string
          name: string
          description: string
          state: GqlModelState
          created: string
          trainingStart?: string | null
          trainingEnd?: string | null
          trainingPeriod?: string | null
          errorMessage?: string | null
          type: { __typename: 'ModelType'; id: string; name: string }
          method: { __typename: 'ModelMethod'; id: string; name: string }
          tag: {
            __typename: 'Tag'
            id: string
            tagName: string
            engUnit?: string | null
            description?: string | null
            displayName?: string | null
            availableDataTimeRange?: {
              __typename: 'ProcessDataEventTimeRange'
              min: string
              max: string
            } | null
            metadata?: {
              __typename?: 'TagMetadata'
              isBad?: boolean | null
              label?: string | null
            } | null
          }
          inputTags: Array<{
            __typename: 'Tag'
            id: string
            tagName: string
            engUnit?: string | null
            description?: string | null
            displayName?: string | null
            availableDataTimeRange?: {
              __typename: 'ProcessDataEventTimeRange'
              min: string
              max: string
            } | null
            metadata?: {
              __typename?: 'TagMetadata'
              isBad?: boolean | null
              label?: string | null
            } | null
          }>
          activeTrainedModel?: {
            __typename: 'TrainedModel'
            valMae?: number | null
            valRSquare?: number | null
            anomalyCount?: number | null
            dataEnd: string
            dataStart: string
          } | null
          createdBy?: { __typename: 'User'; id: string; name: string } | null
        }
  } | null
}

export type GqlAddInputTagsToModelMutationVariables = Exact<{
  input: GqlAddInputTagsToModelInput
}>

export type GqlAddInputTagsToModelMutation = {
  __typename?: 'Mutation'
  addInputTagsToModel?: {
    __typename: 'AddInputTagsToModelPayload'
    model:
      | {
          __typename: 'AnomalyModel'
          viewerHasStarred: boolean
          id: string
          name: string
          description: string
          state: GqlModelState
          created: string
          trainingStart?: string | null
          trainingEnd?: string | null
          trainingPeriod?: string | null
          errorMessage?: string | null
          type: { __typename: 'ModelType'; id: string; name: string }
          method: { __typename: 'ModelMethod'; id: string; name: string }
          tag: {
            __typename: 'Tag'
            id: string
            tagName: string
            engUnit?: string | null
            description?: string | null
            displayName?: string | null
            availableDataTimeRange?: {
              __typename: 'ProcessDataEventTimeRange'
              min: string
              max: string
            } | null
            metadata?: {
              __typename?: 'TagMetadata'
              isBad?: boolean | null
              label?: string | null
            } | null
          }
          inputTags: Array<{
            __typename: 'Tag'
            id: string
            tagName: string
            engUnit?: string | null
            description?: string | null
            displayName?: string | null
            availableDataTimeRange?: {
              __typename: 'ProcessDataEventTimeRange'
              min: string
              max: string
            } | null
            metadata?: {
              __typename?: 'TagMetadata'
              isBad?: boolean | null
              label?: string | null
            } | null
          }>
          activeTrainedModel?: {
            __typename: 'TrainedModel'
            valMae?: number | null
            valRSquare?: number | null
            anomalyCount?: number | null
            dataEnd: string
            dataStart: string
          } | null
          createdBy?: { __typename: 'User'; id: string; name: string } | null
        }
      | {
          __typename: 'ForecastModel'
          viewerHasStarred: boolean
          id: string
          name: string
          description: string
          state: GqlModelState
          created: string
          trainingStart?: string | null
          trainingEnd?: string | null
          trainingPeriod?: string | null
          errorMessage?: string | null
          type: { __typename: 'ModelType'; id: string; name: string }
          method: { __typename: 'ModelMethod'; id: string; name: string }
          tag: {
            __typename: 'Tag'
            id: string
            tagName: string
            engUnit?: string | null
            description?: string | null
            displayName?: string | null
            availableDataTimeRange?: {
              __typename: 'ProcessDataEventTimeRange'
              min: string
              max: string
            } | null
            metadata?: {
              __typename?: 'TagMetadata'
              isBad?: boolean | null
              label?: string | null
            } | null
          }
          inputTags: Array<{
            __typename: 'Tag'
            id: string
            tagName: string
            engUnit?: string | null
            description?: string | null
            displayName?: string | null
            availableDataTimeRange?: {
              __typename: 'ProcessDataEventTimeRange'
              min: string
              max: string
            } | null
            metadata?: {
              __typename?: 'TagMetadata'
              isBad?: boolean | null
              label?: string | null
            } | null
          }>
          activeTrainedModel?: {
            __typename: 'TrainedModel'
            valMae?: number | null
            valRSquare?: number | null
            anomalyCount?: number | null
            dataEnd: string
            dataStart: string
          } | null
          createdBy?: { __typename: 'User'; id: string; name: string } | null
        }
  } | null
}

export type GqlCreateModelMutationVariables = Exact<{
  input: GqlCreateModelInput
}>

export type GqlCreateModelMutation = {
  __typename?: 'Mutation'
  createModel?: {
    __typename: 'CreateModelPayload'
    model:
      | {
          __typename: 'AnomalyModel'
          viewerHasStarred: boolean
          id: string
          name: string
          description: string
          state: GqlModelState
          created: string
          trainingStart?: string | null
          trainingEnd?: string | null
          trainingPeriod?: string | null
          errorMessage?: string | null
          type: { __typename: 'ModelType'; id: string; name: string }
          method: { __typename: 'ModelMethod'; id: string; name: string }
          tag: {
            __typename: 'Tag'
            id: string
            tagName: string
            engUnit?: string | null
            description?: string | null
            displayName?: string | null
            availableDataTimeRange?: {
              __typename: 'ProcessDataEventTimeRange'
              min: string
              max: string
            } | null
            metadata?: {
              __typename?: 'TagMetadata'
              isBad?: boolean | null
              label?: string | null
            } | null
          }
          inputTags: Array<{
            __typename: 'Tag'
            id: string
            tagName: string
            engUnit?: string | null
            description?: string | null
            displayName?: string | null
            availableDataTimeRange?: {
              __typename: 'ProcessDataEventTimeRange'
              min: string
              max: string
            } | null
            metadata?: {
              __typename?: 'TagMetadata'
              isBad?: boolean | null
              label?: string | null
            } | null
          }>
          activeTrainedModel?: {
            __typename: 'TrainedModel'
            valMae?: number | null
            valRSquare?: number | null
            anomalyCount?: number | null
            dataEnd: string
            dataStart: string
          } | null
          createdBy?: { __typename: 'User'; id: string; name: string } | null
        }
      | {
          __typename: 'ForecastModel'
          viewerHasStarred: boolean
          id: string
          name: string
          description: string
          state: GqlModelState
          created: string
          trainingStart?: string | null
          trainingEnd?: string | null
          trainingPeriod?: string | null
          errorMessage?: string | null
          type: { __typename: 'ModelType'; id: string; name: string }
          method: { __typename: 'ModelMethod'; id: string; name: string }
          tag: {
            __typename: 'Tag'
            id: string
            tagName: string
            engUnit?: string | null
            description?: string | null
            displayName?: string | null
            availableDataTimeRange?: {
              __typename: 'ProcessDataEventTimeRange'
              min: string
              max: string
            } | null
            metadata?: {
              __typename?: 'TagMetadata'
              isBad?: boolean | null
              label?: string | null
            } | null
          }
          inputTags: Array<{
            __typename: 'Tag'
            id: string
            tagName: string
            engUnit?: string | null
            description?: string | null
            displayName?: string | null
            availableDataTimeRange?: {
              __typename: 'ProcessDataEventTimeRange'
              min: string
              max: string
            } | null
            metadata?: {
              __typename?: 'TagMetadata'
              isBad?: boolean | null
              label?: string | null
            } | null
          }>
          activeTrainedModel?: {
            __typename: 'TrainedModel'
            valMae?: number | null
            valRSquare?: number | null
            anomalyCount?: number | null
            dataEnd: string
            dataStart: string
          } | null
          createdBy?: { __typename: 'User'; id: string; name: string } | null
        }
  } | null
}

export type GqlUpdateModelAnomalyConditions_AnomalyModel_Fragment = {
  __typename: 'AnomalyModel'
  id: string
  anomalyConditions: Array<{
    __typename?: 'AnomalyCondition'
    threshold: number
    thresholdKind: GqlThresholdKind
    errorScore: {
      __typename?: 'ErrorScore'
      kind: GqlErrorScoreKind
      transform: GqlTransform
    }
  }>
}

export type GqlUpdateModelAnomalyConditions_ForecastModel_Fragment = {
  __typename: 'ForecastModel'
  id: string
}

export type GqlUpdateModelAnomalyConditionsFragment =
  | GqlUpdateModelAnomalyConditions_AnomalyModel_Fragment
  | GqlUpdateModelAnomalyConditions_ForecastModel_Fragment

export type GqlUpdateModelAnomalyConditionsMutationVariables = Exact<{
  input: GqlUpdateModelAnomalyConditionsInput
}>

export type GqlUpdateModelAnomalyConditionsMutation = {
  __typename?: 'Mutation'
  updateModelAnomalyConditions?: {
    __typename: 'UpdateModelAnomalyConditionsPayload'
    model:
      | {
          __typename: 'AnomalyModel'
          id: string
          anomalyConditions: Array<{
            __typename?: 'AnomalyCondition'
            threshold: number
            thresholdKind: GqlThresholdKind
            errorScore: {
              __typename?: 'ErrorScore'
              kind: GqlErrorScoreKind
              transform: GqlTransform
            }
          }>
        }
      | { __typename: 'ForecastModel'; id: string }
  } | null
}

export type GqlUpdateModelThresholds_AnomalyModel_Fragment = {
  __typename: 'AnomalyModel'
  spikeAnomalyThreshold?: number | null
  shortAnomalyThreshold?: number | null
  id: string
}

export type GqlUpdateModelThresholds_ForecastModel_Fragment = {
  __typename: 'ForecastModel'
  id: string
}

export type GqlUpdateModelThresholdsFragment =
  | GqlUpdateModelThresholds_AnomalyModel_Fragment
  | GqlUpdateModelThresholds_ForecastModel_Fragment

export type GqlUpdateModelThresholdsMutationVariables = Exact<{
  input: GqlUpdateModelThresholdsInput
}>

export type GqlUpdateModelThresholdsMutation = {
  __typename?: 'Mutation'
  updateModelThresholds?: {
    __typename: 'UpdateModelThresholdsPayload'
    model:
      | {
          __typename: 'AnomalyModel'
          spikeAnomalyThreshold?: number | null
          shortAnomalyThreshold?: number | null
          id: string
        }
      | { __typename: 'ForecastModel'; id: string }
  } | null
}

export type GqlUpdateModelFilter_AnomalyModel_Fragment = {
  __typename: 'AnomalyModel'
  anomalyGenerationFilter?: string | null
  id: string
}

export type GqlUpdateModelFilter_ForecastModel_Fragment = {
  __typename: 'ForecastModel'
  id: string
}

export type GqlUpdateModelFilterFragment =
  | GqlUpdateModelFilter_AnomalyModel_Fragment
  | GqlUpdateModelFilter_ForecastModel_Fragment

export type GqlUpdateModelFilterMutationVariables = Exact<{
  input: GqlUpdateModelFilterInput
}>

export type GqlUpdateModelFilterMutation = {
  __typename?: 'Mutation'
  updateModelFilter?: {
    __typename: 'UpdateModelFilterPayload'
    model:
      | {
          __typename: 'AnomalyModel'
          anomalyGenerationFilter?: string | null
          id: string
        }
      | { __typename: 'ForecastModel'; id: string }
  } | null
}

export type GqlStarrableState_AnomalyModel_Fragment = {
  __typename?: 'AnomalyModel'
  viewerHasStarred: boolean
}

export type GqlStarrableState_ForecastModel_Fragment = {
  __typename?: 'ForecastModel'
  viewerHasStarred: boolean
}

export type GqlStarrableStateFragment =
  | GqlStarrableState_AnomalyModel_Fragment
  | GqlStarrableState_ForecastModel_Fragment

export type GqlAddStarMutationVariables = Exact<{
  input: GqlAddStarInput
}>

export type GqlAddStarMutation = {
  __typename?: 'Mutation'
  addStar?: {
    __typename: 'AddStarPayload'
    starrable?:
      | { __typename?: 'AnomalyModel'; viewerHasStarred: boolean }
      | { __typename?: 'ForecastModel'; viewerHasStarred: boolean }
      | null
  } | null
}

export type GqlRemoveStarMutationVariables = Exact<{
  input: GqlRemoveStarInput
}>

export type GqlRemoveStarMutation = {
  __typename?: 'Mutation'
  removeStar?: {
    __typename: 'RemoveStarPayload'
    starrable?:
      | { __typename?: 'AnomalyModel'; viewerHasStarred: boolean }
      | { __typename?: 'ForecastModel'; viewerHasStarred: boolean }
      | null
  } | null
}

export type GqlFetchFactoryUsersQueryVariables = Exact<{
  factory: Scalars['ID']['input']
}>

export type GqlFetchFactoryUsersQuery = {
  __typename?: 'Query'
  factory?: {
    __typename?: 'Factory'
    customer: { __typename?: 'Customer'; id: string; name: string }
    users?: {
      __typename?: 'FactoryUserConnection'
      edges?: Array<{
        __typename?: 'FactoryUserEdge'
        role: GqlFactoryRole
        node?: {
          __typename?: 'User'
          id: string
          name: string
          email?: string | null
          status: GqlUserStatus
          createdBy?: { __typename?: 'User'; id: string; name: string } | null
          customer?: {
            __typename?: 'Customer'
            id: string
            name: string
          } | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type GqlGatewayFragment = {
  __typename?: 'Agent'
  id: string
  createdOn: string
  lastPing?: string | null
  version?: string | null
  startTime?: string | null
  hostname?: string | null
  opcConnections?: {
    __typename?: 'OpcConnectionConnection'
    items?: Array<{
      __typename?: 'OpcConnection'
      id: string
      agentId: string
      factoryId: string
      name: string
      endpoint: string
      securityPolicy?: GqlOpcSecurityPolicy | null
      securityMode?: GqlOpcSecurityMode | null
      username?: string | null
      x509Thumbprint?: string | null
      ignoreServerEndpoint: boolean
      configurationState: GqlOpcConnectionConfigurationState
    } | null> | null
  } | null
}

export type GqlFetchFactoryAgentsQueryVariables = Exact<{
  factory: Scalars['ID']['input']
}>

export type GqlFetchFactoryAgentsQuery = {
  __typename?: 'Query'
  factory?: {
    __typename?: 'Factory'
    agents?: {
      __typename?: 'AgentConnection'
      edges?: Array<{
        __typename?: 'AgentEdge'
        node?: {
          __typename?: 'Agent'
          id: string
          createdOn: string
          lastPing?: string | null
          version?: string | null
          startTime?: string | null
          hostname?: string | null
          opcConnections?: {
            __typename?: 'OpcConnectionConnection'
            items?: Array<{
              __typename?: 'OpcConnection'
              id: string
              agentId: string
              factoryId: string
              name: string
              endpoint: string
              securityPolicy?: GqlOpcSecurityPolicy | null
              securityMode?: GqlOpcSecurityMode | null
              username?: string | null
              x509Thumbprint?: string | null
              ignoreServerEndpoint: boolean
              configurationState: GqlOpcConnectionConfigurationState
            } | null> | null
          } | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type GqlFetchFactoryAgentQueryVariables = Exact<{
  factory: Scalars['ID']['input']
  agent: Scalars['ID']['input']
}>

export type GqlFetchFactoryAgentQuery = {
  __typename?: 'Query'
  factory?: {
    __typename?: 'Factory'
    agent?: {
      __typename?: 'Agent'
      id: string
      createdOn: string
      lastPing?: string | null
      version?: string | null
      startTime?: string | null
      hostname?: string | null
      opcConnections?: {
        __typename?: 'OpcConnectionConnection'
        items?: Array<{
          __typename?: 'OpcConnection'
          id: string
          agentId: string
          factoryId: string
          name: string
          endpoint: string
          securityPolicy?: GqlOpcSecurityPolicy | null
          securityMode?: GqlOpcSecurityMode | null
          username?: string | null
          x509Thumbprint?: string | null
          ignoreServerEndpoint: boolean
          configurationState: GqlOpcConnectionConfigurationState
        } | null> | null
      } | null
    } | null
  } | null
}

export type GqlFetchFactoryInvitationsQueryVariables = Exact<{
  factory: Scalars['ID']['input']
}>

export type GqlFetchFactoryInvitationsQuery = {
  __typename?: 'Query'
  factory?: {
    __typename?: 'Factory'
    invitations?: {
      __typename?: 'FactoryInvitationConnection'
      edges?: Array<{
        __typename?: 'FactoryInvitationEdge'
        node?: {
          __typename?: 'FactoryInvitation'
          id: string
          role: GqlFactoryRole
          createdAt: string
          validUntil: string
          completedAt?: string | null
          state: GqlInvitationState
          invitee: {
            __typename?: 'User'
            id: string
            name: string
            email?: string | null
            customer?: {
              __typename?: 'Customer'
              id: string
              name: string
            } | null
          }
          inviter: { __typename?: 'User'; id: string; name: string }
          factory: {
            __typename?: 'Factory'
            id: string
            name: string
            customer: { __typename: 'Customer'; id: string; name: string }
          }
        } | null
      } | null> | null
    } | null
  } | null
}

export type GqlFetchFactoryCreditsQueryVariables = Exact<{
  factory: Scalars['ID']['input']
}>

export type GqlFetchFactoryCreditsQuery = {
  __typename?: 'Query'
  factory?: {
    __typename?: 'Factory'
    activeModelCreditsUsage?: {
      __typename?: 'ActiveModelCreditsUsage'
      total: number
      batch: {
        __typename?: 'ActiveModelTypeCreditsUsage'
        type: GqlActiveModelType
        creditsPerModel: number
        models: number
        total: number
      }
      streaming: {
        __typename?: 'ActiveModelTypeCreditsUsage'
        type: GqlActiveModelType
        creditsPerModel: number
        models: number
        total: number
      }
    } | null
  } | null
}

export type GqlFetchFactoryDexAccessQueryVariables = Exact<{
  factory: Scalars['ID']['input']
}>

export type GqlFetchFactoryDexAccessQuery = {
  __typename?: 'Query'
  factory?: { __typename?: 'Factory'; dexAccess: boolean } | null
}

export type GqlTestOpcUaConnectionQueryVariables = Exact<{
  factory: Scalars['ID']['input']
  agent: Scalars['ID']['input']
  url: Scalars['String']['input']
}>

export type GqlTestOpcUaConnectionQuery = {
  __typename?: 'Query'
  factory?: {
    __typename?: 'Factory'
    agent?: {
      __typename?: 'Agent'
      id: string
      serverEndpoints: {
        __typename?: 'AgentOpcEndpointDescriptionsResponse'
        error?: string | null
        endpoints?: Array<{
          __typename?: 'OpcEndpointDescription'
          url: string
          securityMode: GqlOpcSecurityMode
          securityPolicy: GqlOpcSecurityPolicy
          userIdentityTokens: Array<{
            __typename?: 'OpcUserIdentityToken'
            policyId: string
            userTokenType: GqlOpcUserTokenType
            issuedTokenType: string
            issuerEndpointUrl: string
            securityPolicy?: GqlOpcSecurityPolicy | null
          }>
          server?: {
            __typename?: 'OpcApplicationDescription'
            applicationURI: string
            productURI: string
            applicationName: string
            applicationType: string
            gatewayServerURI: string
            discoveryProfileURI: string
            discoveryUrls: Array<string>
          } | null
        }> | null
      }
      listCertificates: {
        __typename?: 'AgentCertificatesResponse'
        error?: string | null
        certificates?: Array<{
          __typename?: 'AgentCertificate'
          path: string
          thumbprint: string
          serialNumber: string
          createdAt?: string | null
          expiresAt?: string | null
        }> | null
      }
    } | null
  } | null
}

export type GqlFetchOpcConnectionsQueryVariables = Exact<{
  factory: Scalars['ID']['input']
  agent: Scalars['ID']['input']
}>

export type GqlFetchOpcConnectionsQuery = {
  __typename?: 'Query'
  factory?: {
    __typename?: 'Factory'
    agent?: {
      __typename?: 'Agent'
      id: string
      opcConnections?: {
        __typename?: 'OpcConnectionConnection'
        items?: Array<{
          __typename?: 'OpcConnection'
          id: string
          agentId: string
          factoryId: string
          name: string
          endpoint: string
          securityPolicy?: GqlOpcSecurityPolicy | null
          securityMode?: GqlOpcSecurityMode | null
          username?: string | null
          x509Thumbprint?: string | null
          ignoreServerEndpoint: boolean
          configurationState: GqlOpcConnectionConfigurationState
        } | null> | null
      } | null
    } | null
  } | null
}

export type GqlValidateOpcUaConnectionQueryVariables = Exact<{
  factory: Scalars['ID']['input']
  agentId: Scalars['ID']['input']
  connection: GqlValidateOpcConnectionInput
}>

export type GqlValidateOpcUaConnectionQuery = {
  __typename?: 'Query'
  factory?: {
    __typename?: 'Factory'
    agent?: {
      __typename?: 'Agent'
      validateOpcConnection: {
        __typename?: 'ValidateOpcConnectionResponse'
        error?: string | null
        success: boolean
      }
    } | null
  } | null
}

export type GqlNodeValuesFragment = {
  __typename?: 'OpcReadResult'
  nodeId: string
  value?: {
    __typename?: 'OpcDataValue'
    sourceTimestamp?: string | null
    serverTimestamp?: string | null
    value:
      | { __typename?: 'OpcValueBoolean'; booleanValue: boolean }
      | { __typename?: 'OpcValueDateTime'; dateTimeValue: string }
      | { __typename?: 'OpcValueDouble'; doubleValue: number }
      | { __typename?: 'OpcValueError'; error: string }
      | { __typename?: 'OpcValueFloat'; floatValue: number }
      | { __typename?: 'OpcValueInt'; intValue: number }
      | { __typename?: 'OpcValueLong'; longValue: number }
      | { __typename?: 'OpcValueShort'; shortValue: number }
      | { __typename?: 'OpcValueString'; stringValue: string }
      | { __typename?: 'OpcValueUInt'; uintValue: number }
      | { __typename?: 'OpcValueULong'; ulongValue: number }
      | { __typename?: 'OpcValueUShort'; ushortValue: number }
    status: {
      __typename?: 'OpcStatus'
      value: number
      severity: GqlOpcStatusSeverity
    }
  } | null
}

export type GqlReadNodeValuesQueryVariables = Exact<{
  connectionId: Scalars['ID']['input']
  nodeIds: Array<Scalars['String']['input']> | Scalars['String']['input']
  timeout: Scalars['Milliseconds']['input']
}>

export type GqlReadNodeValuesQuery = {
  __typename?: 'Query'
  node?:
    | { __typename: 'Agent'; id: string }
    | { __typename: 'Anomaly'; id: string }
    | { __typename: 'AnomalyComment'; id: string }
    | { __typename: 'AnomalyModel'; id: string }
    | { __typename: 'Asset'; id: string }
    | { __typename: 'Customer'; id: string }
    | { __typename: 'Factory'; id: string }
    | { __typename: 'FactoryInvitation'; id: string }
    | { __typename: 'ForecastModel'; id: string }
    | { __typename: 'HierarchyAsset'; id: string }
    | { __typename: 'HierarchyTag'; id: string }
    | { __typename: 'ModelMethod'; id: string }
    | { __typename: 'ModelType'; id: string }
    | {
        __typename: 'OpcConnection'
        id: string
        readValues: Array<{
          __typename?: 'OpcReadResult'
          nodeId: string
          value?: {
            __typename?: 'OpcDataValue'
            sourceTimestamp?: string | null
            serverTimestamp?: string | null
            value:
              | { __typename?: 'OpcValueBoolean'; booleanValue: boolean }
              | { __typename?: 'OpcValueDateTime'; dateTimeValue: string }
              | { __typename?: 'OpcValueDouble'; doubleValue: number }
              | { __typename?: 'OpcValueError'; error: string }
              | { __typename?: 'OpcValueFloat'; floatValue: number }
              | { __typename?: 'OpcValueInt'; intValue: number }
              | { __typename?: 'OpcValueLong'; longValue: number }
              | { __typename?: 'OpcValueShort'; shortValue: number }
              | { __typename?: 'OpcValueString'; stringValue: string }
              | { __typename?: 'OpcValueUInt'; uintValue: number }
              | { __typename?: 'OpcValueULong'; ulongValue: number }
              | { __typename?: 'OpcValueUShort'; ushortValue: number }
            status: {
              __typename?: 'OpcStatus'
              value: number
              severity: GqlOpcStatusSeverity
            }
          } | null
        }>
      }
    | { __typename: 'OpcStream'; id: string }
    | { __typename: 'Tag'; id: string }
    | { __typename: 'TrainedModel'; id: string }
    | { __typename: 'TrainingTask'; id: string }
    | { __typename: 'TrendView'; id: string }
    | { __typename: 'User'; id: string }
    | null
}

export type GqlBrowseNodeFragment = {
  __typename?: 'OpcBrowseResponse'
  node:
    | {
        __typename: 'ObjectOpcNode'
        nodeId: string
        browseName: string
        displayName: string
        description: string
      }
    | {
        __typename: 'UnknownOpcNode'
        nodeId: string
        browseName: string
        displayName: string
        description: string
      }
    | {
        __typename: 'VariableOpcNode'
        dataType?: string | null
        nodeId: string
        browseName: string
        displayName: string
        description: string
      }
  children: Array<
    | {
        __typename: 'ObjectOpcNode'
        nodeId: string
        browseName: string
        displayName: string
        description: string
      }
    | {
        __typename: 'UnknownOpcNode'
        nodeId: string
        browseName: string
        displayName: string
        description: string
      }
    | {
        __typename: 'VariableOpcNode'
        dataType?: string | null
        nodeId: string
        browseName: string
        displayName: string
        description: string
      }
  >
}

export type GqlOpcNode_ObjectOpcNode_Fragment = {
  __typename: 'ObjectOpcNode'
  nodeId: string
  browseName: string
  displayName: string
  description: string
}

export type GqlOpcNode_UnknownOpcNode_Fragment = {
  __typename: 'UnknownOpcNode'
  nodeId: string
  browseName: string
  displayName: string
  description: string
}

export type GqlOpcNode_VariableOpcNode_Fragment = {
  __typename: 'VariableOpcNode'
  dataType?: string | null
  nodeId: string
  browseName: string
  displayName: string
  description: string
}

export type GqlOpcNodeFragment =
  | GqlOpcNode_ObjectOpcNode_Fragment
  | GqlOpcNode_UnknownOpcNode_Fragment
  | GqlOpcNode_VariableOpcNode_Fragment

export type GqlOpcBrowseQueryVariables = Exact<{
  connectionId: Scalars['ID']['input']
  nodeId: Scalars['String']['input']
  timeout: Scalars['Milliseconds']['input']
}>

export type GqlOpcBrowseQuery = {
  __typename?: 'Query'
  node?:
    | { __typename: 'Agent'; id: string }
    | { __typename: 'Anomaly'; id: string }
    | { __typename: 'AnomalyComment'; id: string }
    | { __typename: 'AnomalyModel'; id: string }
    | { __typename: 'Asset'; id: string }
    | { __typename: 'Customer'; id: string }
    | { __typename: 'Factory'; id: string }
    | { __typename: 'FactoryInvitation'; id: string }
    | { __typename: 'ForecastModel'; id: string }
    | { __typename: 'HierarchyAsset'; id: string }
    | { __typename: 'HierarchyTag'; id: string }
    | { __typename: 'ModelMethod'; id: string }
    | { __typename: 'ModelType'; id: string }
    | {
        __typename: 'OpcConnection'
        name: string
        id: string
        browse: {
          __typename?: 'OpcBrowseResponse'
          node:
            | {
                __typename: 'ObjectOpcNode'
                nodeId: string
                browseName: string
                displayName: string
                description: string
              }
            | {
                __typename: 'UnknownOpcNode'
                nodeId: string
                browseName: string
                displayName: string
                description: string
              }
            | {
                __typename: 'VariableOpcNode'
                dataType?: string | null
                nodeId: string
                browseName: string
                displayName: string
                description: string
              }
          children: Array<
            | {
                __typename: 'ObjectOpcNode'
                nodeId: string
                browseName: string
                displayName: string
                description: string
              }
            | {
                __typename: 'UnknownOpcNode'
                nodeId: string
                browseName: string
                displayName: string
                description: string
              }
            | {
                __typename: 'VariableOpcNode'
                dataType?: string | null
                nodeId: string
                browseName: string
                displayName: string
                description: string
              }
          >
        }
      }
    | { __typename: 'OpcStream'; id: string }
    | { __typename: 'Tag'; id: string }
    | { __typename: 'TrainedModel'; id: string }
    | { __typename: 'TrainingTask'; id: string }
    | { __typename: 'TrendView'; id: string }
    | { __typename: 'User'; id: string }
    | null
}

export type GqlOpcStreamFragment = {
  __typename?: 'OpcStream'
  id: string
  agentId: string
  lastUpdated: string
  revision: number
  opcConnectionId: string
  autoStart: boolean
  batchInterval: number
  heartbeatNode: string
  heartbeatInterval: number
  nodes?: {
    __typename?: 'OpcStreamNodeConnection'
    totalCount?: number | null
  } | null
}

export type GqlGetStreamsQueryVariables = Exact<{
  agentId: Scalars['ID']['input']
}>

export type GqlGetStreamsQuery = {
  __typename?: 'Query'
  node?:
    | {
        __typename: 'Agent'
        opcStreams?: {
          __typename?: 'OpcStreamConnection'
          items?: Array<{
            __typename?: 'OpcStream'
            id: string
            agentId: string
            lastUpdated: string
            revision: number
            opcConnectionId: string
            autoStart: boolean
            batchInterval: number
            heartbeatNode: string
            heartbeatInterval: number
            nodes?: {
              __typename?: 'OpcStreamNodeConnection'
              totalCount?: number | null
            } | null
          } | null> | null
        } | null
      }
    | { __typename?: 'Anomaly' }
    | { __typename?: 'AnomalyComment' }
    | { __typename?: 'AnomalyModel' }
    | { __typename?: 'Asset' }
    | { __typename?: 'Customer' }
    | { __typename?: 'Factory' }
    | { __typename?: 'FactoryInvitation' }
    | { __typename?: 'ForecastModel' }
    | { __typename?: 'HierarchyAsset' }
    | { __typename?: 'HierarchyTag' }
    | { __typename?: 'ModelMethod' }
    | { __typename?: 'ModelType' }
    | { __typename?: 'OpcConnection' }
    | { __typename?: 'OpcStream' }
    | { __typename?: 'Tag' }
    | { __typename?: 'TrainedModel' }
    | { __typename?: 'TrainingTask' }
    | { __typename?: 'TrendView' }
    | { __typename?: 'User' }
    | null
}

export type GqlGetStreamQueryVariables = Exact<{
  streamId: Scalars['ID']['input']
}>

export type GqlGetStreamQuery = {
  __typename?: 'Query'
  node?:
    | { __typename?: 'Agent' }
    | { __typename?: 'Anomaly' }
    | { __typename?: 'AnomalyComment' }
    | { __typename?: 'AnomalyModel' }
    | { __typename?: 'Asset' }
    | { __typename?: 'Customer' }
    | { __typename?: 'Factory' }
    | { __typename?: 'FactoryInvitation' }
    | { __typename?: 'ForecastModel' }
    | { __typename?: 'HierarchyAsset' }
    | { __typename?: 'HierarchyTag' }
    | { __typename?: 'ModelMethod' }
    | { __typename?: 'ModelType' }
    | { __typename?: 'OpcConnection' }
    | {
        __typename: 'OpcStream'
        id: string
        agentId: string
        lastUpdated: string
        revision: number
        opcConnectionId: string
        autoStart: boolean
        batchInterval: number
        heartbeatNode: string
        heartbeatInterval: number
        nodes?: {
          __typename?: 'OpcStreamNodeConnection'
          totalCount?: number | null
        } | null
      }
    | { __typename?: 'Tag' }
    | { __typename?: 'TrainedModel' }
    | { __typename?: 'TrainingTask' }
    | { __typename?: 'TrendView' }
    | { __typename?: 'User' }
    | null
}

export type GqlGetStreamNodesQueryVariables = Exact<{
  streamId: Scalars['ID']['input']
  first: Scalars['Int']['input']
  cursor?: InputMaybe<Scalars['String']['input']>
}>

export type GqlGetStreamNodesQuery = {
  __typename?: 'Query'
  node?:
    | { __typename?: 'Agent' }
    | { __typename?: 'Anomaly' }
    | { __typename?: 'AnomalyComment' }
    | { __typename?: 'AnomalyModel' }
    | { __typename?: 'Asset' }
    | { __typename?: 'Customer' }
    | { __typename?: 'Factory' }
    | { __typename?: 'FactoryInvitation' }
    | { __typename?: 'ForecastModel' }
    | { __typename?: 'HierarchyAsset' }
    | { __typename?: 'HierarchyTag' }
    | { __typename?: 'ModelMethod' }
    | { __typename?: 'ModelType' }
    | { __typename?: 'OpcConnection' }
    | {
        __typename: 'OpcStream'
        id: string
        nodes?: {
          __typename?: 'OpcStreamNodeConnection'
          totalCount?: number | null
          pageInfo: {
            __typename?: 'PageInfo'
            hasNextPage: boolean
            hasPreviousPage: boolean
            startCursor?: string | null
            endCursor?: string | null
          }
          items?: Array<{
            __typename?: 'OpcStreamNode'
            nodeId: string
          } | null> | null
        } | null
      }
    | { __typename?: 'Tag' }
    | { __typename?: 'TrainedModel' }
    | { __typename?: 'TrainingTask' }
    | { __typename?: 'TrendView' }
    | { __typename?: 'User' }
    | null
}

export type GqlSetFactoryUserRoleMutationVariables = Exact<{
  input: GqlSetFactoryUserRoleInput
}>

export type GqlSetFactoryUserRoleMutation = {
  __typename?: 'Mutation'
  setFactoryUserRole?: {
    __typename: 'SetFactoryUserRolePayload'
    factory?: { __typename?: 'Factory'; id: string } | null
    userEdge?: {
      __typename?: 'FactoryUserEdge'
      role: GqlFactoryRole
      node?: { __typename?: 'User'; id: string; name: string } | null
    } | null
  } | null
}

export type GqlRemoveFactoryUserMutationVariables = Exact<{
  input: GqlRemoveFactoryUserInput
}>

export type GqlRemoveFactoryUserMutation = {
  __typename?: 'Mutation'
  removeFactoryUser?: {
    __typename: 'RemoveFactoryUserPayload'
    factory?: { __typename?: 'Factory'; id: string } | null
    userEdge?: {
      __typename?: 'FactoryUserEdge'
      role: GqlFactoryRole
      node?: { __typename?: 'User'; id: string; name: string } | null
    } | null
  } | null
}

export type GqlRemoveCustomerUserMutationVariables = Exact<{
  input: GqlRemoveCustomerUserInput
}>

export type GqlRemoveCustomerUserMutation = {
  __typename?: 'Mutation'
  removeCustomerUser?: {
    __typename: 'RemoveCustomerUserPayload'
    customer: { __typename?: 'Customer'; id: string }
    userEdge: {
      __typename?: 'CustomerUserEdge'
      role: GqlCustomerRole
      node?: { __typename?: 'User'; id: string; name: string } | null
    }
  } | null
}

export type GqlUpdateUserConsentMutationVariables = Exact<{
  input: GqlUpdateUserConsentInput
}>

export type GqlUpdateUserConsentMutation = {
  __typename?: 'Mutation'
  updateUserConsent?: {
    __typename: 'UpdateUserConsentPayload'
    user: {
      __typename?: 'User'
      id: string
      cookieConsent: boolean
      dataCollectionConsent: boolean
      name: string
    }
  } | null
}

export type GqlUpdateUserDefaultFactoryMutationVariables = Exact<{
  input: GqlUpdateUserDefaultFactoryInput
}>

export type GqlUpdateUserDefaultFactoryMutation = {
  __typename?: 'Mutation'
  updateUserDefaultFactory?: {
    __typename: 'UpdateUserDefaultFactoryPayload'
    user: { __typename?: 'User'; id: string; defaultFactoryId?: string | null }
  } | null
}

export type GqlFetchFactoryQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GqlFetchFactoryQuery = {
  __typename?: 'Query'
  factory?: {
    __typename: 'Factory'
    id: string
    name: string
    dexAccess: boolean
    viewerRole?: GqlFactoryRole | null
    userCount: number
    customer: { __typename?: 'Customer'; id: string; name: string }
    agents?: {
      __typename?: 'AgentConnection'
      items?: Array<{ __typename: 'Agent'; id: string } | null> | null
    } | null
  } | null
}

export type GqlFetchMyFactoriesQueryVariables = Exact<{ [key: string]: never }>

export type GqlFetchMyFactoriesQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    factories: Array<{
      __typename?: 'Factory'
      id: string
      name: string
      viewerRole?: GqlFactoryRole | null
      customer: { __typename?: 'Customer'; id: string; name: string }
      users?: {
        __typename?: 'FactoryUserConnection'
        items?: Array<{ __typename?: 'User'; id: string } | null> | null
      } | null
      agents?: {
        __typename?: 'AgentConnection'
        items?: Array<{ __typename?: 'Agent'; id: string } | null> | null
      } | null
    }>
  }
}

export type GqlFetchCustomerQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GqlFetchCustomerQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    customer?: { __typename?: 'Customer'; id: string } | null
  }
  customer?: {
    __typename?: 'Customer'
    id: string
    name: string
    viewerRole?: GqlCustomerRole | null
    factoryCount: number
    factories?: {
      __typename?: 'FactoryConnection'
      items?: Array<{
        __typename?: 'Factory'
        id: string
        name: string
        viewerRole?: GqlFactoryRole | null
        userCount: number
        agents?: {
          __typename?: 'AgentConnection'
          items?: Array<{ __typename?: 'Agent'; id: string } | null> | null
        } | null
      } | null> | null
    } | null
    users?: {
      __typename?: 'CustomerUserConnection'
      edges?: Array<{
        __typename?: 'CustomerUserEdge'
        role: GqlCustomerRole
        node?: {
          __typename?: 'User'
          id: string
          name: string
          email?: string | null
          status: GqlUserStatus
          customer?: {
            __typename?: 'Customer'
            id: string
            name: string
          } | null
        } | null
      } | null> | null
    } | null
  } | null
}

export type GqlDisableUserMutationVariables = Exact<{
  input: GqlDisableUserInput
}>

export type GqlDisableUserMutation = {
  __typename?: 'Mutation'
  disableUser?: {
    __typename: 'DisableUserPayload'
    user: {
      __typename?: 'User'
      id: string
      cookieConsent: boolean
      dataCollectionConsent: boolean
      name: string
    }
  } | null
}

export type GqlAddUserMutationVariables = Exact<{
  input: GqlAddUserInput
}>

export type GqlAddUserMutation = {
  __typename?: 'Mutation'
  addUser?: {
    __typename: 'AddUserPayload'
    user: {
      __typename?: 'User'
      id: string
      cookieConsent: boolean
      dataCollectionConsent: boolean
      name: string
    }
  } | null
}

export type GqlSetCustomerUserRoleMutationVariables = Exact<{
  input: GqlSetCustomerUserRoleInput
}>

export type GqlSetCustomerUserRoleMutation = {
  __typename?: 'Mutation'
  setCustomerUserRole?: {
    __typename: 'SetCustomerUserRolePayload'
    customer?: { __typename?: 'Customer'; id: string } | null
    userEdge?: {
      __typename?: 'CustomerUserEdge'
      role: GqlCustomerRole
      node?: { __typename?: 'User'; id: string; name: string } | null
    } | null
  } | null
}

export type GqlInviteFactoryUserMutationVariables = Exact<{
  input: GqlInviteFactoryUserInput
}>

export type GqlInviteFactoryUserMutation = {
  __typename?: 'Mutation'
  inviteFactoryUser?: {
    __typename: 'InviteFactoryUserPayload'
    invitation: {
      __typename?: 'FactoryInvitation'
      id: string
      state: GqlInvitationState
    }
  } | null
}

export type GqlAcceptFactoryInvitationMutationVariables = Exact<{
  input: GqlAcceptFactoryInvitationInput
}>

export type GqlAcceptFactoryInvitationMutation = {
  __typename?: 'Mutation'
  acceptFactoryInvitation?: {
    __typename?: 'AcceptFactoryInvitationPayload'
    invitation: {
      __typename?: 'FactoryInvitation'
      id: string
      state: GqlInvitationState
    }
  } | null
}

export type GqlDeclineFactoryInvitationMutationVariables = Exact<{
  input: GqlDeclineFactoryInvitationInput
}>

export type GqlDeclineFactoryInvitationMutation = {
  __typename?: 'Mutation'
  declineFactoryInvitation?: {
    __typename?: 'DeclineFactoryInvitationPayload'
    invitation: {
      __typename?: 'FactoryInvitation'
      id: string
      state: GqlInvitationState
    }
  } | null
}

export type GqlCancelFactoryInvitationMutationVariables = Exact<{
  input: GqlCancelFactoryInvitationInput
}>

export type GqlCancelFactoryInvitationMutation = {
  __typename?: 'Mutation'
  cancelFactoryInvitation?: {
    __typename?: 'CancelFactoryInvitationPayload'
    invitation: {
      __typename?: 'FactoryInvitation'
      id: string
      state: GqlInvitationState
    }
  } | null
}

export type GqlAnomalyCountByStateQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GqlAnomalyCountByStateQuery = {
  __typename?: 'Query'
  factory?: {
    __typename?: 'Factory'
    anomalyCountByState: Array<{
      __typename?: 'AnomalyStateCount'
      state: GqlAnomalyState
      count: number
    }>
  } | null
}

export type GqlUpdatedTagFragment = {
  __typename?: 'Tag'
  id: string
  tagName: string
  description?: string | null
  displayName?: string | null
  engUnit?: string | null
}

export type GqlUpdateTagMutationVariables = Exact<{
  input: GqlUpdateTagInput
}>

export type GqlUpdateTagMutation = {
  __typename?: 'Mutation'
  updateTag?: {
    __typename: 'UpdateTagPayload'
    tag: {
      __typename?: 'Tag'
      id: string
      tagName: string
      description?: string | null
      displayName?: string | null
      engUnit?: string | null
    }
  } | null
}

export type GqlTagDetailsFragment = {
  __typename?: 'Tag'
  id: string
  tagName: string
  description?: string | null
  valueType?: string | null
  engUnit?: string | null
  displayName?: string | null
  availableDataTimeRange?: {
    __typename?: 'ProcessDataEventTimeRange'
    min: string
    max: string
  } | null
  metadata?: {
    __typename?: 'TagMetadata'
    isBad?: boolean | null
    label?: string | null
  } | null
}

export type GqlFetchTagQueryVariables = Exact<{
  factory: Scalars['ID']['input']
  tagName: Scalars['String']['input']
}>

export type GqlFetchTagQuery = {
  __typename?: 'Query'
  factory?: {
    __typename?: 'Factory'
    tagByName?: {
      __typename?: 'Tag'
      id: string
      tagName: string
      description?: string | null
      valueType?: string | null
      engUnit?: string | null
      displayName?: string | null
      availableDataTimeRange?: {
        __typename?: 'ProcessDataEventTimeRange'
        min: string
        max: string
      } | null
      metadata?: {
        __typename?: 'TagMetadata'
        isBad?: boolean | null
        label?: string | null
      } | null
    } | null
  } | null
}

export type GqlBasicAnomalyCommentFragment = {
  __typename?: 'AnomalyComment'
  id: string
  comment: string
  by: string
  at: string
}

export type GqlAnomalyBaseFragment = {
  __typename?: 'Anomaly'
  id: string
  start: string
  score?: number | null
  state: GqlAnomalyState
  end?: string | null
  inProgress: boolean
  restId: number
  comments: Array<{
    __typename?: 'AnomalyComment'
    id: string
    comment: string
    by: string
    at: string
    anomaly: {
      __typename?: 'Anomaly'
      id: string
      start: string
      model: { __typename?: 'AnomalyModel'; id: string; name: string }
    }
  }>
  model: {
    __typename?: 'AnomalyModel'
    id: string
    name: string
    typeId: string
    state: GqlModelState
    tag: {
      __typename?: 'Tag'
      tagName: string
      displayName?: string | null
      id: string
    }
  }
}

export type GqlFetchAnomaliesQueryVariables = Exact<{
  factory: Scalars['ID']['input']
  from: Scalars['DateTime']['input']
  to: Scalars['DateTime']['input']
}>

export type GqlFetchAnomaliesQuery = {
  __typename?: 'Query'
  factory?: {
    __typename?: 'Factory'
    anomalies?: {
      __typename?: 'AnomalyConnection'
      edges?: Array<{
        __typename?: 'AnomalyEdge'
        node?: {
          __typename?: 'Anomaly'
          id: string
          start: string
          score?: number | null
          state: GqlAnomalyState
          end?: string | null
          inProgress: boolean
          restId: number
          comments: Array<{
            __typename?: 'AnomalyComment'
            id: string
            comment: string
            by: string
            at: string
            anomaly: {
              __typename?: 'Anomaly'
              id: string
              start: string
              model: { __typename?: 'AnomalyModel'; id: string; name: string }
            }
          }>
          model: {
            __typename?: 'AnomalyModel'
            id: string
            name: string
            typeId: string
            state: GqlModelState
            tag: {
              __typename?: 'Tag'
              tagName: string
              displayName?: string | null
              id: string
            }
          }
        } | null
      } | null> | null
    } | null
  } | null
}

export type GqlNavigationAnomalyFragment = {
  __typename?: 'Anomaly'
  id: string
  start: string
  end?: string | null
  score?: number | null
}

export type GqlFetchAnomalyFragment = {
  __typename?: 'Anomaly'
  id: string
  start: string
  score?: number | null
  state: GqlAnomalyState
  end?: string | null
  inProgress: boolean
  restId: number
  previous?: {
    __typename?: 'Anomaly'
    id: string
    start: string
    end?: string | null
    score?: number | null
  } | null
  next?: {
    __typename?: 'Anomaly'
    id: string
    start: string
    end?: string | null
    score?: number | null
  } | null
  comments: Array<{
    __typename?: 'AnomalyComment'
    id: string
    comment: string
    by: string
    at: string
    anomaly: {
      __typename?: 'Anomaly'
      id: string
      start: string
      model: { __typename?: 'AnomalyModel'; id: string; name: string }
    }
  }>
  model: {
    __typename?: 'AnomalyModel'
    id: string
    name: string
    typeId: string
    state: GqlModelState
    tag: {
      __typename?: 'Tag'
      tagName: string
      displayName?: string | null
      id: string
    }
  }
}

export type GqlFetchAnomalyQueryVariables = Exact<{
  factory: Scalars['ID']['input']
  anomaly: Scalars['ID']['input']
}>

export type GqlFetchAnomalyQuery = {
  __typename?: 'Query'
  factory?: {
    __typename: 'Factory'
    anomaly?: {
      __typename?: 'Anomaly'
      id: string
      start: string
      score?: number | null
      state: GqlAnomalyState
      end?: string | null
      inProgress: boolean
      restId: number
      previous?: {
        __typename?: 'Anomaly'
        id: string
        start: string
        end?: string | null
        score?: number | null
      } | null
      next?: {
        __typename?: 'Anomaly'
        id: string
        start: string
        end?: string | null
        score?: number | null
      } | null
      comments: Array<{
        __typename?: 'AnomalyComment'
        id: string
        comment: string
        by: string
        at: string
        anomaly: {
          __typename?: 'Anomaly'
          id: string
          start: string
          model: { __typename?: 'AnomalyModel'; id: string; name: string }
        }
      }>
      model: {
        __typename?: 'AnomalyModel'
        id: string
        name: string
        typeId: string
        state: GqlModelState
        tag: {
          __typename?: 'Tag'
          tagName: string
          displayName?: string | null
          id: string
        }
      }
    } | null
  } | null
}

export type GqlAnomalyCommentFragment = {
  __typename?: 'AnomalyComment'
  id: string
  comment: string
  by: string
  at: string
  anomaly: {
    __typename?: 'Anomaly'
    id: string
    start: string
    model: { __typename?: 'AnomalyModel'; id: string; name: string }
  }
}

export type GqlFetchLatestCommentsQueryVariables = Exact<{
  factory: Scalars['ID']['input']
}>

export type GqlFetchLatestCommentsQuery = {
  __typename?: 'Query'
  factory?: {
    __typename?: 'Factory'
    latestAnomalyComments: Array<{
      __typename?: 'AnomalyComment'
      id: string
      comment: string
      by: string
      at: string
      anomaly: {
        __typename?: 'Anomaly'
        id: string
        start: string
        model: { __typename?: 'AnomalyModel'; id: string; name: string }
      }
    }>
  } | null
}

export type GqlAnomalyCommentBaseFragment = {
  __typename?: 'AnomalyComment'
  id: string
  comment: string
  anomaly: { __typename?: 'Anomaly'; id: string }
}

export type GqlAddAnomalyCommentMutationVariables = Exact<{
  input: GqlAddAnomalyCommentInput
}>

export type GqlAddAnomalyCommentMutation = {
  __typename?: 'Mutation'
  addAnomalyComment?: {
    __typename: 'AddAnomalyCommentPayload'
    comment: {
      __typename?: 'AnomalyComment'
      id: string
      comment: string
      anomaly: { __typename?: 'Anomaly'; id: string }
    }
  } | null
}

export type GqlSetAnomalyStateMutationVariables = Exact<{
  input: GqlSetAnomalyStateInput
}>

export type GqlSetAnomalyStateMutation = {
  __typename?: 'Mutation'
  setAnomalyState?: {
    __typename: 'SetAnomalyStatePayload'
    anomaly: {
      __typename?: 'Anomaly'
      id: string
      start: string
      score?: number | null
      state: GqlAnomalyState
      end?: string | null
      inProgress: boolean
      restId: number
      previous?: {
        __typename?: 'Anomaly'
        id: string
        start: string
        end?: string | null
        score?: number | null
      } | null
      next?: {
        __typename?: 'Anomaly'
        id: string
        start: string
        end?: string | null
        score?: number | null
      } | null
      comments: Array<{
        __typename?: 'AnomalyComment'
        id: string
        comment: string
        by: string
        at: string
        anomaly: {
          __typename?: 'Anomaly'
          id: string
          start: string
          model: { __typename?: 'AnomalyModel'; id: string; name: string }
        }
      }>
      model: {
        __typename?: 'AnomalyModel'
        id: string
        name: string
        typeId: string
        state: GqlModelState
        tag: {
          __typename?: 'Tag'
          tagName: string
          displayName?: string | null
          id: string
        }
      }
    }
  } | null
}

export type GqlFetchDocsLinkQueryVariables = Exact<{ [key: string]: never }>

export type GqlFetchDocsLinkQuery = {
  __typename?: 'Query'
  documentationUrl: {
    __typename?: 'DocumentationUrl'
    url: string
    token: string
    expiry: string
  }
}

export type GqlFactoryTrendViewFragment = {
  __typename?: 'TrendView'
  id: string
  name: string
  data: string
  shared: boolean
  createdAt: string
  factoryId: string
  updatedAt: string
  user: { __typename?: 'User'; id: string; name: string }
}

export type GqlFetchFactoryTrendViewsQueryVariables = Exact<{
  factory: Scalars['ID']['input']
}>

export type GqlFetchFactoryTrendViewsQuery = {
  __typename?: 'Query'
  factory?: {
    __typename?: 'Factory'
    trendViews?: {
      __typename?: 'TrendViewConnection'
      items?: Array<{
        __typename?: 'TrendView'
        id: string
        name: string
        data: string
        shared: boolean
        createdAt: string
        factoryId: string
        updatedAt: string
        user: { __typename?: 'User'; id: string; name: string }
      } | null> | null
    } | null
  } | null
}

export type GqlCreateTrendViewMutationVariables = Exact<{
  input: GqlCreateTrendViewInput
}>

export type GqlCreateTrendViewMutation = {
  __typename?: 'Mutation'
  createTrendView?: {
    __typename?: 'CreateTrendViewPayload'
    trendView: {
      __typename?: 'TrendView'
      id: string
      name: string
      data: string
      shared: boolean
      createdAt: string
      factoryId: string
      updatedAt: string
      user: { __typename?: 'User'; id: string; name: string }
    }
  } | null
}

export type GqlUpdateTrendViewMutationVariables = Exact<{
  input: GqlUpdateTrendViewInput
}>

export type GqlUpdateTrendViewMutation = {
  __typename?: 'Mutation'
  updateTrendView?: {
    __typename?: 'UpdateTrendViewPayload'
    trendView: {
      __typename?: 'TrendView'
      id: string
      name: string
      data: string
      shared: boolean
      createdAt: string
      factoryId: string
      updatedAt: string
      user: { __typename?: 'User'; id: string; name: string }
    }
  } | null
}

export type GqlDeleteTrendViewMutationVariables = Exact<{
  input: GqlDeleteTrendViewInput
}>

export type GqlDeleteTrendViewMutation = {
  __typename?: 'Mutation'
  deleteTrendView?: {
    __typename?: 'DeleteTrendViewPayload'
    trendView: {
      __typename?: 'TrendView'
      id: string
      name: string
      data: string
      shared: boolean
      createdAt: string
      factoryId: string
      updatedAt: string
      user: { __typename?: 'User'; id: string; name: string }
    }
  } | null
}

export type GqlOpcConnectionFragment = {
  __typename?: 'OpcConnection'
  id: string
  agentId: string
  factoryId: string
  name: string
  endpoint: string
  securityPolicy?: GqlOpcSecurityPolicy | null
  securityMode?: GqlOpcSecurityMode | null
  username?: string | null
  x509Thumbprint?: string | null
  ignoreServerEndpoint: boolean
  configurationState: GqlOpcConnectionConfigurationState
}

export type GqlCreateOpcConnectionMutationVariables = Exact<{
  input: GqlCreateOpcConnectionInput
}>

export type GqlCreateOpcConnectionMutation = {
  __typename?: 'Mutation'
  createOpcConnection?: {
    __typename?: 'CreateOpcConnectionPayload'
    connection: {
      __typename?: 'OpcConnection'
      id: string
      agentId: string
      factoryId: string
      name: string
      endpoint: string
      securityPolicy?: GqlOpcSecurityPolicy | null
      securityMode?: GqlOpcSecurityMode | null
      username?: string | null
      x509Thumbprint?: string | null
      ignoreServerEndpoint: boolean
      configurationState: GqlOpcConnectionConfigurationState
    }
  } | null
}

export type GqlUpdateOpcConnectionMutationVariables = Exact<{
  input: GqlUpdateOpcConnectionInput
}>

export type GqlUpdateOpcConnectionMutation = {
  __typename?: 'Mutation'
  updateOpcConnection?: {
    __typename?: 'UpdateOpcConnectionPayload'
    connection: {
      __typename?: 'OpcConnection'
      id: string
      agentId: string
      factoryId: string
      name: string
      endpoint: string
      securityPolicy?: GqlOpcSecurityPolicy | null
      securityMode?: GqlOpcSecurityMode | null
      username?: string | null
      x509Thumbprint?: string | null
      ignoreServerEndpoint: boolean
      configurationState: GqlOpcConnectionConfigurationState
    }
  } | null
}

export const InvitationBaseFragmentDoc = gql`
  fragment InvitationBase on FactoryInvitation {
    id
    role
    createdAt
    validUntil
    completedAt
    state
    inviter {
      id
      name
    }
    factory {
      id
      name
      customer {
        __typename
        id
        name
      }
    }
  }
`
export const MeFragmentDoc = gql`
  fragment Me on User {
    id
    name
    email
    dataCollectionConsent
    cookieConsent
    factories {
      id
      name
      customer {
        id
        name
      }
      viewerRole
    }
    customers {
      items {
        name
        id
        viewerRole
        userCount
        factoryCount
      }
    }
    customer {
      id
    }
    defaultFactoryId
    hasAppAccess
    invitations {
      items {
        ...InvitationBase
      }
    }
  }
  ${InvitationBaseFragmentDoc}
`
export const TsDataFragmentDoc = gql`
  fragment TsData on TimeSeriesData {
    __typename
    timestamp
    value: avg
    minValue: min
    maxValue: max
  }
`
export const ModelTypeFragmentDoc = gql`
  fragment ModelType on ModelType {
    __typename
    id
    name
    methods {
      __typename
      id
      name
    }
    defaultMethod {
      __typename
      id
      name
    }
  }
`
export const ModelTagBaseFragmentDoc = gql`
  fragment ModelTagBase on Tag {
    __typename
    id
    tagName
    engUnit
    description
    displayName
    availableDataTimeRange {
      __typename
      min
      max
    }
    metadata {
      isBad
      label
    }
  }
`
export const ModelBaseFragmentDoc = gql`
  fragment ModelBase on Model {
    __typename
    id
    name
    description
    state
    created
    trainingStart
    trainingEnd
    trainingPeriod
    errorMessage
    type {
      __typename
      id
      name
    }
    method {
      __typename
      id
      name
    }
    tag {
      ...ModelTagBase
    }
    inputTags {
      ...ModelTagBase
    }
    activeTrainedModel {
      __typename
      valMae
      valRSquare
      anomalyCount
      dataEnd
      dataStart
    }
    createdBy {
      __typename
      id
      name
    }
    ... on Starrable {
      viewerHasStarred
    }
  }
  ${ModelTagBaseFragmentDoc}
`
export const AnomalyConditionsFragmentDoc = gql`
  fragment AnomalyConditions on AnomalyCondition {
    errorScore {
      kind
      transform
    }
    threshold
    thresholdKind
  }
`
export const ModelAnomalyCommentFragmentDoc = gql`
  fragment ModelAnomalyComment on AnomalyComment {
    at
    by
    comment
  }
`
export const ModelAnomalyFragmentDoc = gql`
  fragment ModelAnomaly on Anomaly {
    id
    restId
    start
    end
    score
    state
    inProgress
    comments {
      ...ModelAnomalyComment
    }
  }
  ${ModelAnomalyCommentFragmentDoc}
`
export const FetchModelFragmentDoc = gql`
  fragment FetchModel on Model {
    ...ModelBase
    ... on AnomalyModel {
      anomalyGenerationFilter
      availableErrorScores {
        kind
        transform
      }
      anomalyConditions {
        ...AnomalyConditions
      }
      initialInferenceStart
      initialInferenceEnd
      anomalies {
        ...ModelAnomaly
      }
    }
    ... on ForecastModel {
      initialInferenceStart
      initialInferenceEnd
    }
  }
  ${ModelBaseFragmentDoc}
  ${AnomalyConditionsFragmentDoc}
  ${ModelAnomalyFragmentDoc}
`
export const ModelIdStateFragmentDoc = gql`
  fragment ModelIdState on Model {
    __typename
    id
    state
  }
`
export const UpdateModelAnomalyConditionsFragmentDoc = gql`
  fragment UpdateModelAnomalyConditions on Model {
    __typename
    id
    ... on AnomalyModel {
      anomalyConditions {
        ...AnomalyConditions
      }
    }
  }
  ${AnomalyConditionsFragmentDoc}
`
export const UpdateModelThresholdsFragmentDoc = gql`
  fragment UpdateModelThresholds on Model {
    __typename
    id
    ... on AnomalyModel {
      spikeAnomalyThreshold
      shortAnomalyThreshold
    }
  }
`
export const UpdateModelFilterFragmentDoc = gql`
  fragment UpdateModelFilter on Model {
    __typename
    id
    ... on AnomalyModel {
      anomalyGenerationFilter
    }
  }
`
export const StarrableStateFragmentDoc = gql`
  fragment StarrableState on Starrable {
    viewerHasStarred
  }
`
export const OpcConnectionFragmentDoc = gql`
  fragment OpcConnection on OpcConnection {
    id
    agentId
    factoryId
    name
    endpoint
    securityPolicy
    securityMode
    username
    x509Thumbprint
    ignoreServerEndpoint
    configurationState
  }
`
export const GatewayFragmentDoc = gql`
  fragment Gateway on Agent {
    id
    createdOn
    lastPing
    version
    startTime
    hostname
    opcConnections {
      items {
        ...OpcConnection
      }
    }
  }
  ${OpcConnectionFragmentDoc}
`
export const NodeValuesFragmentDoc = gql`
  fragment NodeValues on OpcReadResult {
    nodeId
    value {
      value {
        ... on OpcValueBoolean {
          booleanValue: value
        }
        ... on OpcValueShort {
          shortValue: value
        }
        ... on OpcValueInt {
          intValue: value
        }
        ... on OpcValueLong {
          longValue: value
        }
        ... on OpcValueUShort {
          ushortValue: value
        }
        ... on OpcValueUInt {
          uintValue: value
        }
        ... on OpcValueULong {
          ulongValue: value
        }
        ... on OpcValueFloat {
          floatValue: value
        }
        ... on OpcValueDouble {
          doubleValue: value
        }
        ... on OpcValueString {
          stringValue: value
        }
        ... on OpcValueDateTime {
          dateTimeValue: value
        }
        ... on OpcValueError {
          error
        }
      }
      status {
        value
        severity
      }
      sourceTimestamp
      serverTimestamp
    }
  }
`
export const OpcNodeFragmentDoc = gql`
  fragment OpcNode on OpcNode {
    __typename
    nodeId
    browseName
    displayName
    description
    ... on VariableOpcNode {
      dataType
    }
  }
`
export const BrowseNodeFragmentDoc = gql`
  fragment BrowseNode on OpcBrowseResponse {
    node {
      ...OpcNode
    }
    children {
      ...OpcNode
    }
  }
  ${OpcNodeFragmentDoc}
`
export const OpcStreamFragmentDoc = gql`
  fragment OpcStream on OpcStream {
    id
    agentId
    lastUpdated
    revision
    opcConnectionId
    autoStart
    batchInterval
    heartbeatNode
    heartbeatInterval
    nodes {
      totalCount
    }
  }
`
export const UpdatedTagFragmentDoc = gql`
  fragment UpdatedTag on Tag {
    id
    tagName
    description
    displayName
    engUnit
  }
`
export const TagDetailsFragmentDoc = gql`
  fragment TagDetails on Tag {
    id
    tagName
    description
    valueType
    engUnit
    displayName
    availableDataTimeRange {
      min
      max
    }
    metadata {
      isBad
      label
    }
  }
`
export const BasicAnomalyCommentFragmentDoc = gql`
  fragment BasicAnomalyComment on AnomalyComment {
    id
    comment
    by
    at
  }
`
export const AnomalyCommentFragmentDoc = gql`
  fragment AnomalyComment on AnomalyComment {
    id
    comment
    by
    at
    anomaly {
      id
      start
      model {
        id
        name
      }
    }
  }
`
export const AnomalyBaseFragmentDoc = gql`
  fragment AnomalyBase on Anomaly {
    id
    start
    score
    state
    end
    inProgress
    restId
    comments {
      ...AnomalyComment
    }
    model {
      id
      name
      typeId
      state
      tag {
        tagName
        displayName
        id
      }
    }
  }
  ${AnomalyCommentFragmentDoc}
`
export const NavigationAnomalyFragmentDoc = gql`
  fragment NavigationAnomaly on Anomaly {
    id
    start
    end
    score
  }
`
export const FetchAnomalyFragmentDoc = gql`
  fragment FetchAnomaly on Anomaly {
    ...AnomalyBase
    previous {
      ...NavigationAnomaly
    }
    next {
      ...NavigationAnomaly
    }
  }
  ${AnomalyBaseFragmentDoc}
  ${NavigationAnomalyFragmentDoc}
`
export const AnomalyCommentBaseFragmentDoc = gql`
  fragment AnomalyCommentBase on AnomalyComment {
    id
    comment
    anomaly {
      id
    }
  }
`
export const FactoryTrendViewFragmentDoc = gql`
  fragment FactoryTrendView on TrendView {
    id
    name
    data
    shared
    createdAt
    factoryId
    updatedAt
    user {
      id
      name
    }
  }
`
export const MeDocument = gql`
  query Me {
    me {
      ...Me
    }
  }
  ${MeFragmentDoc}
`
export const FetchModelAnomalyScoreDocument = gql`
  query FetchModelAnomalyScore(
    $id: ID!
    $from: DateTime!
    $to: DateTime!
    $interval: Int!
    $errorScoreKind: ErrorScoreKind!
    $transform: Transform!
  ) {
    node(id: $id) {
      __typename
      id
      ... on AnomalyModel {
        errorScore2(
          from: $from
          to: $to
          interval: $interval
          errorScoreKind: $errorScoreKind
          transform: $transform
        ) {
          __typename
          data {
            ...TsData
          }
        }
      }
    }
  }
  ${TsDataFragmentDoc}
`
export const ProcessDataDocument = gql`
  query ProcessData(
    $factory: ID!
    $tagName: String!
    $from: DateTime!
    $to: DateTime!
    $interval: Int!
  ) {
    factory(id: $factory) {
      __typename
      tagByName(tagname: $tagName) {
        __typename
        processData(from: $from, to: $to, interval: $interval) {
          __typename
          data {
            ...TsData
          }
        }
      }
    }
  }
  ${TsDataFragmentDoc}
`
export const PredictionsDocument = gql`
  query Predictions(
    $id: ID!
    $from: DateTime!
    $to: DateTime!
    $interval: Int!
  ) {
    node(id: $id) {
      __typename
      id
      ... on AnomalyModel {
        prediction(from: $from, to: $to, interval: $interval) {
          __typename
          data {
            ...TsData
          }
        }
      }
    }
  }
  ${TsDataFragmentDoc}
`
export const FetchModelTypesDocument = gql`
  query FetchModelTypes {
    modelTypes {
      ...ModelType
    }
  }
  ${ModelTypeFragmentDoc}
`
export const FetchModelsDocument = gql`
  query FetchModels($factory: ID!) {
    factory(id: $factory) {
      __typename
      models {
        __typename
        items {
          ...ModelBase
        }
      }
    }
  }
  ${ModelBaseFragmentDoc}
`
export const FetchModelDocument = gql`
  query FetchModel($modelId: ID!) {
    node(id: $modelId) {
      ...FetchModel
    }
  }
  ${FetchModelFragmentDoc}
`
export const ArchiveModelDocument = gql`
  mutation ArchiveModel($input: ArchiveModelInput!) {
    archiveModel(input: $input) {
      __typename
      model {
        ...ModelIdState
      }
    }
  }
  ${ModelIdStateFragmentDoc}
`
export const DeleteModelDocument = gql`
  mutation DeleteModel($input: DeleteModelInput!) {
    deleteModel(input: $input) {
      __typename
      model {
        ...ModelIdState
      }
    }
  }
  ${ModelIdStateFragmentDoc}
`
export const StartModelDocument = gql`
  mutation StartModel($input: StartModelInput!) {
    startModel(input: $input) {
      __typename
      model {
        ...ModelIdState
      }
    }
  }
  ${ModelIdStateFragmentDoc}
`
export const StopModelDocument = gql`
  mutation StopModel($input: StopModelInput!) {
    stopModel(input: $input) {
      __typename
      model {
        ...ModelIdState
      }
    }
  }
  ${ModelIdStateFragmentDoc}
`
export const ActivateModelDocument = gql`
  mutation ActivateModel($input: ActivateModelInput!) {
    activateModel(input: $input) {
      __typename
      model {
        ...ModelIdState
      }
    }
  }
  ${ModelIdStateFragmentDoc}
`
export const RunAnomalyDetectionDocument = gql`
  mutation RunAnomalyDetection($input: RunAnomalyDetectionInput!) {
    runAnomalyDetection(input: $input) {
      __typename
      model {
        ...ModelIdState
      }
    }
  }
  ${ModelIdStateFragmentDoc}
`
export const UpdateModelDocument = gql`
  mutation UpdateModel($input: UpdateModelInput!) {
    updateModel(input: $input) {
      __typename
      model {
        ...ModelBase
      }
    }
  }
  ${ModelBaseFragmentDoc}
`
export const RemoveInputTagsFromModelDocument = gql`
  mutation RemoveInputTagsFromModel($input: RemoveInputTagsFromModelInput!) {
    removeInputTagsFromModel(input: $input) {
      __typename
      model {
        ...ModelBase
      }
    }
  }
  ${ModelBaseFragmentDoc}
`
export const AddInputTagsToModelDocument = gql`
  mutation AddInputTagsToModel($input: AddInputTagsToModelInput!) {
    addInputTagsToModel(input: $input) {
      __typename
      model {
        ...ModelBase
      }
    }
  }
  ${ModelBaseFragmentDoc}
`
export const CreateModelDocument = gql`
  mutation CreateModel($input: CreateModelInput!) {
    createModel(input: $input) {
      __typename
      model {
        ...ModelBase
      }
    }
  }
  ${ModelBaseFragmentDoc}
`
export const UpdateModelAnomalyConditionsDocument = gql`
  mutation UpdateModelAnomalyConditions(
    $input: UpdateModelAnomalyConditionsInput!
  ) {
    updateModelAnomalyConditions(input: $input) {
      __typename
      model {
        ...UpdateModelAnomalyConditions
      }
    }
  }
  ${UpdateModelAnomalyConditionsFragmentDoc}
`
export const UpdateModelThresholdsDocument = gql`
  mutation UpdateModelThresholds($input: UpdateModelThresholdsInput!) {
    updateModelThresholds(input: $input) {
      __typename
      model {
        ...UpdateModelThresholds
      }
    }
  }
  ${UpdateModelThresholdsFragmentDoc}
`
export const UpdateModelFilterDocument = gql`
  mutation UpdateModelFilter($input: UpdateModelFilterInput!) {
    updateModelFilter(input: $input) {
      __typename
      model {
        ...UpdateModelFilter
      }
    }
  }
  ${UpdateModelFilterFragmentDoc}
`
export const AddStarDocument = gql`
  mutation AddStar($input: AddStarInput!) {
    addStar(input: $input) {
      __typename
      starrable {
        ...StarrableState
      }
    }
  }
  ${StarrableStateFragmentDoc}
`
export const RemoveStarDocument = gql`
  mutation RemoveStar($input: RemoveStarInput!) {
    removeStar(input: $input) {
      __typename
      starrable {
        ...StarrableState
      }
    }
  }
  ${StarrableStateFragmentDoc}
`
export const FetchFactoryUsersDocument = gql`
  query FetchFactoryUsers($factory: ID!) {
    factory(id: $factory) {
      customer {
        id
        name
      }
      users {
        edges {
          node {
            id
            name
            email
            status
            createdBy {
              id
              name
            }
            customer {
              id
              name
            }
          }
          role
        }
      }
    }
  }
`
export const FetchFactoryAgentsDocument = gql`
  query FetchFactoryAgents($factory: ID!) {
    factory(id: $factory) {
      agents {
        edges {
          node {
            ...Gateway
          }
        }
      }
    }
  }
  ${GatewayFragmentDoc}
`
export const FetchFactoryAgentDocument = gql`
  query FetchFactoryAgent($factory: ID!, $agent: ID!) {
    factory(id: $factory) {
      agent(id: $agent) {
        ...Gateway
      }
    }
  }
  ${GatewayFragmentDoc}
`
export const FetchFactoryInvitationsDocument = gql`
  query FetchFactoryInvitations($factory: ID!) {
    factory(id: $factory) {
      invitations {
        edges {
          node {
            ...InvitationBase
            invitee {
              id
              name
              email
              customer {
                id
                name
              }
            }
          }
        }
      }
    }
  }
  ${InvitationBaseFragmentDoc}
`
export const FetchFactoryCreditsDocument = gql`
  query FetchFactoryCredits($factory: ID!) {
    factory(id: $factory) {
      activeModelCreditsUsage {
        total
        batch {
          type
          creditsPerModel
          models
          total
        }
        streaming {
          type
          creditsPerModel
          models
          total
        }
      }
    }
  }
`
export const FetchFactoryDexAccessDocument = gql`
  query FetchFactoryDexAccess($factory: ID!) {
    factory(id: $factory) {
      dexAccess
    }
  }
`
export const TestOpcUaConnectionDocument = gql`
  query TestOpcUaConnection($factory: ID!, $agent: ID!, $url: String!) {
    factory(id: $factory) {
      agent(id: $agent) {
        id
        serverEndpoints(url: $url) {
          error
          endpoints {
            url
            securityMode
            securityPolicy
            userIdentityTokens {
              policyId
              userTokenType
              issuedTokenType
              issuerEndpointUrl
              securityPolicy
            }
            server {
              applicationURI
              productURI
              applicationName
              applicationType
              gatewayServerURI
              discoveryProfileURI
              discoveryUrls
            }
          }
        }
        listCertificates {
          error
          certificates {
            path
            thumbprint
            serialNumber
            createdAt
            expiresAt
          }
        }
      }
    }
  }
`
export const FetchOpcConnectionsDocument = gql`
  query FetchOpcConnections($factory: ID!, $agent: ID!) {
    factory(id: $factory) {
      agent(id: $agent) {
        id
        opcConnections {
          items {
            ...OpcConnection
          }
        }
      }
    }
  }
  ${OpcConnectionFragmentDoc}
`
export const ValidateOpcUaConnectionDocument = gql`
  query ValidateOpcUaConnection(
    $factory: ID!
    $agentId: ID!
    $connection: ValidateOpcConnectionInput!
  ) {
    factory(id: $factory) {
      agent(id: $agentId) {
        validateOpcConnection(connection: $connection) {
          error
          success
        }
      }
    }
  }
`
export const ReadNodeValuesDocument = gql`
  query ReadNodeValues(
    $connectionId: ID!
    $nodeIds: [String!]!
    $timeout: Milliseconds!
  ) {
    node(id: $connectionId) {
      id
      __typename
      ... on OpcConnection {
        readValues(nodeIds: $nodeIds, timeout: $timeout) {
          ...NodeValues
        }
      }
    }
  }
  ${NodeValuesFragmentDoc}
`
export const OpcBrowseDocument = gql`
  query OpcBrowse(
    $connectionId: ID!
    $nodeId: String!
    $timeout: Milliseconds!
  ) {
    node(id: $connectionId) {
      id
      __typename
      ... on OpcConnection {
        name
        browse(nodeId: $nodeId, timeout: $timeout) {
          ...BrowseNode
        }
      }
    }
  }
  ${BrowseNodeFragmentDoc}
`
export const GetStreamsDocument = gql`
  query GetStreams($agentId: ID!) {
    node(id: $agentId) {
      ... on Agent {
        __typename
        opcStreams {
          items {
            ...OpcStream
          }
        }
      }
    }
  }
  ${OpcStreamFragmentDoc}
`
export const GetStreamDocument = gql`
  query GetStream($streamId: ID!) {
    node(id: $streamId) {
      ... on OpcStream {
        __typename
        ...OpcStream
      }
    }
  }
  ${OpcStreamFragmentDoc}
`
export const GetStreamNodesDocument = gql`
  query GetStreamNodes($streamId: ID!, $first: Int!, $cursor: String) {
    node(id: $streamId) {
      ... on OpcStream {
        __typename
        id
        nodes(first: $first, after: $cursor) {
          totalCount
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          items {
            nodeId
          }
        }
      }
    }
  }
`
export const SetFactoryUserRoleDocument = gql`
  mutation SetFactoryUserRole($input: SetFactoryUserRoleInput!) {
    setFactoryUserRole(input: $input) {
      __typename
      factory {
        id
      }
      userEdge {
        role
        node {
          id
          name
        }
      }
    }
  }
`
export const RemoveFactoryUserDocument = gql`
  mutation RemoveFactoryUser($input: RemoveFactoryUserInput!) {
    removeFactoryUser(input: $input) {
      __typename
      factory {
        id
      }
      userEdge {
        role
        node {
          id
          name
        }
      }
    }
  }
`
export const RemoveCustomerUserDocument = gql`
  mutation RemoveCustomerUser($input: RemoveCustomerUserInput!) {
    removeCustomerUser(input: $input) {
      __typename
      customer {
        id
      }
      userEdge {
        role
        node {
          id
          name
        }
      }
    }
  }
`
export const UpdateUserConsentDocument = gql`
  mutation UpdateUserConsent($input: UpdateUserConsentInput!) {
    updateUserConsent(input: $input) {
      __typename
      user {
        id
        cookieConsent
        dataCollectionConsent
        name
      }
    }
  }
`
export const UpdateUserDefaultFactoryDocument = gql`
  mutation UpdateUserDefaultFactory($input: UpdateUserDefaultFactoryInput!) {
    updateUserDefaultFactory(input: $input) {
      __typename
      user {
        id
        defaultFactoryId
      }
    }
  }
`
export const FetchFactoryDocument = gql`
  query FetchFactory($id: ID!) {
    factory(id: $id) {
      __typename
      id
      name
      customer {
        id
        name
      }
      dexAccess
      viewerRole
      userCount
      agents {
        items {
          __typename
          id
        }
      }
    }
  }
`
export const FetchMyFactoriesDocument = gql`
  query FetchMyFactories {
    me {
      factories {
        id
        name
        viewerRole
        customer {
          id
          name
        }
        users {
          items {
            id
          }
        }
        agents {
          items {
            id
          }
        }
      }
    }
  }
`
export const FetchCustomerDocument = gql`
  query FetchCustomer($id: ID!) {
    me {
      customer {
        id
      }
    }
    customer(id: $id) {
      id
      name
      viewerRole
      factoryCount
      factories {
        items {
          id
          name
          viewerRole
          userCount
          agents {
            items {
              id
            }
          }
        }
      }
      users {
        edges {
          node {
            id
            name
            email
            status
            customer {
              id
              name
            }
          }
          role
        }
      }
    }
  }
`
export const DisableUserDocument = gql`
  mutation DisableUser($input: DisableUserInput!) {
    disableUser(input: $input) {
      __typename
      user {
        id
        cookieConsent
        dataCollectionConsent
        name
      }
    }
  }
`
export const AddUserDocument = gql`
  mutation AddUser($input: AddUserInput!) {
    addUser(input: $input) {
      __typename
      user {
        id
        cookieConsent
        dataCollectionConsent
        name
      }
    }
  }
`
export const SetCustomerUserRoleDocument = gql`
  mutation SetCustomerUserRole($input: SetCustomerUserRoleInput!) {
    setCustomerUserRole(input: $input) {
      __typename
      customer {
        id
      }
      userEdge {
        role
        node {
          id
          name
        }
      }
    }
  }
`
export const InviteFactoryUserDocument = gql`
  mutation InviteFactoryUser($input: InviteFactoryUserInput!) {
    inviteFactoryUser(input: $input) {
      __typename
      invitation {
        id
        state
      }
    }
  }
`
export const AcceptFactoryInvitationDocument = gql`
  mutation AcceptFactoryInvitation($input: AcceptFactoryInvitationInput!) {
    acceptFactoryInvitation(input: $input) {
      invitation {
        id
        state
      }
    }
  }
`
export const DeclineFactoryInvitationDocument = gql`
  mutation DeclineFactoryInvitation($input: DeclineFactoryInvitationInput!) {
    declineFactoryInvitation(input: $input) {
      invitation {
        id
        state
      }
    }
  }
`
export const CancelFactoryInvitationDocument = gql`
  mutation CancelFactoryInvitation($input: CancelFactoryInvitationInput!) {
    cancelFactoryInvitation(input: $input) {
      invitation {
        id
        state
      }
    }
  }
`
export const AnomalyCountByStateDocument = gql`
  query AnomalyCountByState($id: ID!) {
    factory(id: $id) {
      anomalyCountByState {
        state
        count
      }
    }
  }
`
export const UpdateTagDocument = gql`
  mutation UpdateTag($input: UpdateTagInput!) {
    updateTag(input: $input) {
      __typename
      tag {
        ...UpdatedTag
      }
    }
  }
  ${UpdatedTagFragmentDoc}
`
export const FetchTagDocument = gql`
  query FetchTag($factory: ID!, $tagName: String!) {
    factory(id: $factory) {
      tagByName(tagname: $tagName) {
        ...TagDetails
      }
    }
  }
  ${TagDetailsFragmentDoc}
`
export const FetchAnomaliesDocument = gql`
  query FetchAnomalies($factory: ID!, $from: DateTime!, $to: DateTime!) {
    factory(id: $factory) {
      anomalies(from: $from, to: $to) {
        edges {
          node {
            ...AnomalyBase
          }
        }
      }
    }
  }
  ${AnomalyBaseFragmentDoc}
`
export const FetchAnomalyDocument = gql`
  query FetchAnomaly($factory: ID!, $anomaly: ID!) {
    factory(id: $factory) {
      __typename
      anomaly(id: $anomaly) {
        ...FetchAnomaly
      }
    }
  }
  ${FetchAnomalyFragmentDoc}
`
export const FetchLatestCommentsDocument = gql`
  query FetchLatestComments($factory: ID!) {
    factory(id: $factory) {
      latestAnomalyComments {
        ...AnomalyComment
      }
    }
  }
  ${AnomalyCommentFragmentDoc}
`
export const AddAnomalyCommentDocument = gql`
  mutation AddAnomalyComment($input: AddAnomalyCommentInput!) {
    addAnomalyComment(input: $input) {
      __typename
      comment {
        ...AnomalyCommentBase
      }
    }
  }
  ${AnomalyCommentBaseFragmentDoc}
`
export const SetAnomalyStateDocument = gql`
  mutation SetAnomalyState($input: SetAnomalyStateInput!) {
    setAnomalyState(input: $input) {
      __typename
      anomaly {
        ...FetchAnomaly
      }
    }
  }
  ${FetchAnomalyFragmentDoc}
`
export const FetchDocsLinkDocument = gql`
  query FetchDocsLink {
    documentationUrl {
      url
      token
      expiry
    }
  }
`
export const FetchFactoryTrendViewsDocument = gql`
  query FetchFactoryTrendViews($factory: ID!) {
    factory(id: $factory) {
      trendViews {
        items {
          ...FactoryTrendView
        }
      }
    }
  }
  ${FactoryTrendViewFragmentDoc}
`
export const CreateTrendViewDocument = gql`
  mutation createTrendView($input: CreateTrendViewInput!) {
    createTrendView(input: $input) {
      trendView {
        ...FactoryTrendView
      }
    }
  }
  ${FactoryTrendViewFragmentDoc}
`
export const UpdateTrendViewDocument = gql`
  mutation updateTrendView($input: UpdateTrendViewInput!) {
    updateTrendView(input: $input) {
      trendView {
        ...FactoryTrendView
      }
    }
  }
  ${FactoryTrendViewFragmentDoc}
`
export const DeleteTrendViewDocument = gql`
  mutation deleteTrendView($input: DeleteTrendViewInput!) {
    deleteTrendView(input: $input) {
      trendView {
        ...FactoryTrendView
      }
    }
  }
  ${FactoryTrendViewFragmentDoc}
`
export const CreateOpcConnectionDocument = gql`
  mutation createOpcConnection($input: CreateOpcConnectionInput!) {
    createOpcConnection(input: $input) {
      connection {
        ...OpcConnection
      }
    }
  }
  ${OpcConnectionFragmentDoc}
`
export const UpdateOpcConnectionDocument = gql`
  mutation updateOpcConnection($input: UpdateOpcConnectionInput!) {
    updateOpcConnection(input: $input) {
      connection {
        ...OpcConnection
      }
    }
  }
  ${OpcConnectionFragmentDoc}
`

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any,
) => Promise<T>

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType,
  _variables,
) => action()

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper,
) {
  return {
    Me(
      variables?: GqlMeQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlMeQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlMeQuery>(MeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'Me',
        'query',
        variables,
      )
    },
    FetchModelAnomalyScore(
      variables: GqlFetchModelAnomalyScoreQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlFetchModelAnomalyScoreQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlFetchModelAnomalyScoreQuery>(
            FetchModelAnomalyScoreDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'FetchModelAnomalyScore',
        'query',
        variables,
      )
    },
    ProcessData(
      variables: GqlProcessDataQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlProcessDataQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlProcessDataQuery>(ProcessDataDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'ProcessData',
        'query',
        variables,
      )
    },
    Predictions(
      variables: GqlPredictionsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlPredictionsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlPredictionsQuery>(PredictionsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'Predictions',
        'query',
        variables,
      )
    },
    FetchModelTypes(
      variables?: GqlFetchModelTypesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlFetchModelTypesQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlFetchModelTypesQuery>(
            FetchModelTypesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'FetchModelTypes',
        'query',
        variables,
      )
    },
    FetchModels(
      variables: GqlFetchModelsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlFetchModelsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlFetchModelsQuery>(FetchModelsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'FetchModels',
        'query',
        variables,
      )
    },
    FetchModel(
      variables: GqlFetchModelQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlFetchModelQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlFetchModelQuery>(FetchModelDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'FetchModel',
        'query',
        variables,
      )
    },
    ArchiveModel(
      variables: GqlArchiveModelMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlArchiveModelMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlArchiveModelMutation>(
            ArchiveModelDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'ArchiveModel',
        'mutation',
        variables,
      )
    },
    DeleteModel(
      variables: GqlDeleteModelMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlDeleteModelMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlDeleteModelMutation>(
            DeleteModelDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'DeleteModel',
        'mutation',
        variables,
      )
    },
    StartModel(
      variables: GqlStartModelMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlStartModelMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlStartModelMutation>(StartModelDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'StartModel',
        'mutation',
        variables,
      )
    },
    StopModel(
      variables: GqlStopModelMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlStopModelMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlStopModelMutation>(StopModelDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'StopModel',
        'mutation',
        variables,
      )
    },
    ActivateModel(
      variables: GqlActivateModelMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlActivateModelMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlActivateModelMutation>(
            ActivateModelDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'ActivateModel',
        'mutation',
        variables,
      )
    },
    RunAnomalyDetection(
      variables: GqlRunAnomalyDetectionMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlRunAnomalyDetectionMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlRunAnomalyDetectionMutation>(
            RunAnomalyDetectionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'RunAnomalyDetection',
        'mutation',
        variables,
      )
    },
    UpdateModel(
      variables: GqlUpdateModelMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlUpdateModelMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlUpdateModelMutation>(
            UpdateModelDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'UpdateModel',
        'mutation',
        variables,
      )
    },
    RemoveInputTagsFromModel(
      variables: GqlRemoveInputTagsFromModelMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlRemoveInputTagsFromModelMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlRemoveInputTagsFromModelMutation>(
            RemoveInputTagsFromModelDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'RemoveInputTagsFromModel',
        'mutation',
        variables,
      )
    },
    AddInputTagsToModel(
      variables: GqlAddInputTagsToModelMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlAddInputTagsToModelMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlAddInputTagsToModelMutation>(
            AddInputTagsToModelDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'AddInputTagsToModel',
        'mutation',
        variables,
      )
    },
    CreateModel(
      variables: GqlCreateModelMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlCreateModelMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlCreateModelMutation>(
            CreateModelDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'CreateModel',
        'mutation',
        variables,
      )
    },
    UpdateModelAnomalyConditions(
      variables: GqlUpdateModelAnomalyConditionsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlUpdateModelAnomalyConditionsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlUpdateModelAnomalyConditionsMutation>(
            UpdateModelAnomalyConditionsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'UpdateModelAnomalyConditions',
        'mutation',
        variables,
      )
    },
    UpdateModelThresholds(
      variables: GqlUpdateModelThresholdsMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlUpdateModelThresholdsMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlUpdateModelThresholdsMutation>(
            UpdateModelThresholdsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'UpdateModelThresholds',
        'mutation',
        variables,
      )
    },
    UpdateModelFilter(
      variables: GqlUpdateModelFilterMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlUpdateModelFilterMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlUpdateModelFilterMutation>(
            UpdateModelFilterDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'UpdateModelFilter',
        'mutation',
        variables,
      )
    },
    AddStar(
      variables: GqlAddStarMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlAddStarMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlAddStarMutation>(AddStarDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'AddStar',
        'mutation',
        variables,
      )
    },
    RemoveStar(
      variables: GqlRemoveStarMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlRemoveStarMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlRemoveStarMutation>(RemoveStarDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'RemoveStar',
        'mutation',
        variables,
      )
    },
    FetchFactoryUsers(
      variables: GqlFetchFactoryUsersQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlFetchFactoryUsersQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlFetchFactoryUsersQuery>(
            FetchFactoryUsersDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'FetchFactoryUsers',
        'query',
        variables,
      )
    },
    FetchFactoryAgents(
      variables: GqlFetchFactoryAgentsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlFetchFactoryAgentsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlFetchFactoryAgentsQuery>(
            FetchFactoryAgentsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'FetchFactoryAgents',
        'query',
        variables,
      )
    },
    FetchFactoryAgent(
      variables: GqlFetchFactoryAgentQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlFetchFactoryAgentQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlFetchFactoryAgentQuery>(
            FetchFactoryAgentDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'FetchFactoryAgent',
        'query',
        variables,
      )
    },
    FetchFactoryInvitations(
      variables: GqlFetchFactoryInvitationsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlFetchFactoryInvitationsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlFetchFactoryInvitationsQuery>(
            FetchFactoryInvitationsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'FetchFactoryInvitations',
        'query',
        variables,
      )
    },
    FetchFactoryCredits(
      variables: GqlFetchFactoryCreditsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlFetchFactoryCreditsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlFetchFactoryCreditsQuery>(
            FetchFactoryCreditsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'FetchFactoryCredits',
        'query',
        variables,
      )
    },
    FetchFactoryDexAccess(
      variables: GqlFetchFactoryDexAccessQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlFetchFactoryDexAccessQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlFetchFactoryDexAccessQuery>(
            FetchFactoryDexAccessDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'FetchFactoryDexAccess',
        'query',
        variables,
      )
    },
    TestOpcUaConnection(
      variables: GqlTestOpcUaConnectionQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlTestOpcUaConnectionQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlTestOpcUaConnectionQuery>(
            TestOpcUaConnectionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'TestOpcUaConnection',
        'query',
        variables,
      )
    },
    FetchOpcConnections(
      variables: GqlFetchOpcConnectionsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlFetchOpcConnectionsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlFetchOpcConnectionsQuery>(
            FetchOpcConnectionsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'FetchOpcConnections',
        'query',
        variables,
      )
    },
    ValidateOpcUaConnection(
      variables: GqlValidateOpcUaConnectionQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlValidateOpcUaConnectionQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlValidateOpcUaConnectionQuery>(
            ValidateOpcUaConnectionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'ValidateOpcUaConnection',
        'query',
        variables,
      )
    },
    ReadNodeValues(
      variables: GqlReadNodeValuesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlReadNodeValuesQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlReadNodeValuesQuery>(
            ReadNodeValuesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'ReadNodeValues',
        'query',
        variables,
      )
    },
    OpcBrowse(
      variables: GqlOpcBrowseQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlOpcBrowseQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlOpcBrowseQuery>(OpcBrowseDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'OpcBrowse',
        'query',
        variables,
      )
    },
    GetStreams(
      variables: GqlGetStreamsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlGetStreamsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlGetStreamsQuery>(GetStreamsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetStreams',
        'query',
        variables,
      )
    },
    GetStream(
      variables: GqlGetStreamQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlGetStreamQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlGetStreamQuery>(GetStreamDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetStream',
        'query',
        variables,
      )
    },
    GetStreamNodes(
      variables: GqlGetStreamNodesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlGetStreamNodesQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlGetStreamNodesQuery>(
            GetStreamNodesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'GetStreamNodes',
        'query',
        variables,
      )
    },
    SetFactoryUserRole(
      variables: GqlSetFactoryUserRoleMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlSetFactoryUserRoleMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlSetFactoryUserRoleMutation>(
            SetFactoryUserRoleDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'SetFactoryUserRole',
        'mutation',
        variables,
      )
    },
    RemoveFactoryUser(
      variables: GqlRemoveFactoryUserMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlRemoveFactoryUserMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlRemoveFactoryUserMutation>(
            RemoveFactoryUserDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'RemoveFactoryUser',
        'mutation',
        variables,
      )
    },
    RemoveCustomerUser(
      variables: GqlRemoveCustomerUserMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlRemoveCustomerUserMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlRemoveCustomerUserMutation>(
            RemoveCustomerUserDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'RemoveCustomerUser',
        'mutation',
        variables,
      )
    },
    UpdateUserConsent(
      variables: GqlUpdateUserConsentMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlUpdateUserConsentMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlUpdateUserConsentMutation>(
            UpdateUserConsentDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'UpdateUserConsent',
        'mutation',
        variables,
      )
    },
    UpdateUserDefaultFactory(
      variables: GqlUpdateUserDefaultFactoryMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlUpdateUserDefaultFactoryMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlUpdateUserDefaultFactoryMutation>(
            UpdateUserDefaultFactoryDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'UpdateUserDefaultFactory',
        'mutation',
        variables,
      )
    },
    FetchFactory(
      variables: GqlFetchFactoryQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlFetchFactoryQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlFetchFactoryQuery>(
            FetchFactoryDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'FetchFactory',
        'query',
        variables,
      )
    },
    FetchMyFactories(
      variables?: GqlFetchMyFactoriesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlFetchMyFactoriesQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlFetchMyFactoriesQuery>(
            FetchMyFactoriesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'FetchMyFactories',
        'query',
        variables,
      )
    },
    FetchCustomer(
      variables: GqlFetchCustomerQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlFetchCustomerQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlFetchCustomerQuery>(
            FetchCustomerDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'FetchCustomer',
        'query',
        variables,
      )
    },
    DisableUser(
      variables: GqlDisableUserMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlDisableUserMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlDisableUserMutation>(
            DisableUserDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'DisableUser',
        'mutation',
        variables,
      )
    },
    AddUser(
      variables: GqlAddUserMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlAddUserMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlAddUserMutation>(AddUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'AddUser',
        'mutation',
        variables,
      )
    },
    SetCustomerUserRole(
      variables: GqlSetCustomerUserRoleMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlSetCustomerUserRoleMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlSetCustomerUserRoleMutation>(
            SetCustomerUserRoleDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'SetCustomerUserRole',
        'mutation',
        variables,
      )
    },
    InviteFactoryUser(
      variables: GqlInviteFactoryUserMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlInviteFactoryUserMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlInviteFactoryUserMutation>(
            InviteFactoryUserDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'InviteFactoryUser',
        'mutation',
        variables,
      )
    },
    AcceptFactoryInvitation(
      variables: GqlAcceptFactoryInvitationMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlAcceptFactoryInvitationMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlAcceptFactoryInvitationMutation>(
            AcceptFactoryInvitationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'AcceptFactoryInvitation',
        'mutation',
        variables,
      )
    },
    DeclineFactoryInvitation(
      variables: GqlDeclineFactoryInvitationMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlDeclineFactoryInvitationMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlDeclineFactoryInvitationMutation>(
            DeclineFactoryInvitationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'DeclineFactoryInvitation',
        'mutation',
        variables,
      )
    },
    CancelFactoryInvitation(
      variables: GqlCancelFactoryInvitationMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlCancelFactoryInvitationMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlCancelFactoryInvitationMutation>(
            CancelFactoryInvitationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'CancelFactoryInvitation',
        'mutation',
        variables,
      )
    },
    AnomalyCountByState(
      variables: GqlAnomalyCountByStateQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlAnomalyCountByStateQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlAnomalyCountByStateQuery>(
            AnomalyCountByStateDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'AnomalyCountByState',
        'query',
        variables,
      )
    },
    UpdateTag(
      variables: GqlUpdateTagMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlUpdateTagMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlUpdateTagMutation>(UpdateTagDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UpdateTag',
        'mutation',
        variables,
      )
    },
    FetchTag(
      variables: GqlFetchTagQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlFetchTagQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlFetchTagQuery>(FetchTagDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'FetchTag',
        'query',
        variables,
      )
    },
    FetchAnomalies(
      variables: GqlFetchAnomaliesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlFetchAnomaliesQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlFetchAnomaliesQuery>(
            FetchAnomaliesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'FetchAnomalies',
        'query',
        variables,
      )
    },
    FetchAnomaly(
      variables: GqlFetchAnomalyQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlFetchAnomalyQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlFetchAnomalyQuery>(
            FetchAnomalyDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'FetchAnomaly',
        'query',
        variables,
      )
    },
    FetchLatestComments(
      variables: GqlFetchLatestCommentsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlFetchLatestCommentsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlFetchLatestCommentsQuery>(
            FetchLatestCommentsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'FetchLatestComments',
        'query',
        variables,
      )
    },
    AddAnomalyComment(
      variables: GqlAddAnomalyCommentMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlAddAnomalyCommentMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlAddAnomalyCommentMutation>(
            AddAnomalyCommentDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'AddAnomalyComment',
        'mutation',
        variables,
      )
    },
    SetAnomalyState(
      variables: GqlSetAnomalyStateMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlSetAnomalyStateMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlSetAnomalyStateMutation>(
            SetAnomalyStateDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'SetAnomalyState',
        'mutation',
        variables,
      )
    },
    FetchDocsLink(
      variables?: GqlFetchDocsLinkQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlFetchDocsLinkQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlFetchDocsLinkQuery>(
            FetchDocsLinkDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'FetchDocsLink',
        'query',
        variables,
      )
    },
    FetchFactoryTrendViews(
      variables: GqlFetchFactoryTrendViewsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlFetchFactoryTrendViewsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlFetchFactoryTrendViewsQuery>(
            FetchFactoryTrendViewsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'FetchFactoryTrendViews',
        'query',
        variables,
      )
    },
    createTrendView(
      variables: GqlCreateTrendViewMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlCreateTrendViewMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlCreateTrendViewMutation>(
            CreateTrendViewDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'createTrendView',
        'mutation',
        variables,
      )
    },
    updateTrendView(
      variables: GqlUpdateTrendViewMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlUpdateTrendViewMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlUpdateTrendViewMutation>(
            UpdateTrendViewDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'updateTrendView',
        'mutation',
        variables,
      )
    },
    deleteTrendView(
      variables: GqlDeleteTrendViewMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlDeleteTrendViewMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlDeleteTrendViewMutation>(
            DeleteTrendViewDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'deleteTrendView',
        'mutation',
        variables,
      )
    },
    createOpcConnection(
      variables: GqlCreateOpcConnectionMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlCreateOpcConnectionMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlCreateOpcConnectionMutation>(
            CreateOpcConnectionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'createOpcConnection',
        'mutation',
        variables,
      )
    },
    updateOpcConnection(
      variables: GqlUpdateOpcConnectionMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GqlUpdateOpcConnectionMutation> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GqlUpdateOpcConnectionMutation>(
            UpdateOpcConnectionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'updateOpcConnection',
        'mutation',
        variables,
      )
    },
  }
}
export type Sdk = ReturnType<typeof getSdk>
