import { z } from 'zod'

// HierarchyAsset
export interface CreateHierarchyAssetDto {
  factoryId?: number
  viewType: number // AssetViewType
  key: number
  parentKey: number
  active: boolean
}

export interface UpdateHierarchyAssetDto {
  id: number
  viewType: number // AssetViewType
  key: number
  parentKey: number
  active: boolean
}

export const HierarchyAssetDto = z.object({
  id: z.number(),
  viewType: z.number(),
  key: z.number(),
  parentKey: z.number(),
  active: z.boolean(),
  assetId: z.number(),
  parentAssetId: z.number().optional().nullable(),
})

export type HierarchyAssetDto = z.infer<typeof HierarchyAssetDto>

// Assets

export interface CreateAssetDto {
  name: string
  alias?: string | null
  key?: number | null
  parentAssetId?: number | null
}

export interface UpdateAssetDto {
  id: number
  alias?: string | null
}

export const InternalAssetDto = z.object({
  id: z.number(),
  key: z.number(),
  name: z.string(),
  alias: z.string().optional().nullable(),
  addedAt: z.string().datetime(),
  modifiedAt: z.string().datetime().optional().nullable(),
  removedAt: z.string().datetime().optional().nullable(),
})

export type InternalAssetDto = z.infer<typeof InternalAssetDto>

// the required fields for PUT /tags/multiple
export interface TagUpdate {
  id: number
  active: boolean
  tagName: string
  parentKey: number
}

export const TagDto = z.object({
  id: z.number(),
  active: z.boolean(),
  assetId: z.number(),
  tagId: z.number(),
  tagNodeId: z.string(),
  hierarchyTagNodeId: z.string(),
  tagName: z.string(),
  displayName: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
  engUnit: z.string().optional().nullable(),
  parentKey: z.number(),
  parentName: z.string().optional().nullable(),
  label: z.string().optional().nullable(),
  isBad: z.boolean().nullable(),
})

export type TagDto = z.infer<typeof TagDto>

export const StreamlitAppDto = z.object({
  id: z.string(),
  slug: z.string(),
  name: z.string(),
  description: z.string(),
  isPublic: z.boolean(),
})

export type StreamlitAppDto = z.infer<typeof StreamlitAppDto>

export const StreamlitSessionDto = z.object({
  id: z.string(),
  appId: z.string(),
  path: z.string(),
})

export type StreamlitSessionDto = z.infer<typeof StreamlitSessionDto>

export const ForecastValueDto = z.object({
  timestamp: z.string().datetime(),
  value: z.number(),
  minValue: z.number().optional().nullable(),
  maxValue: z.number().optional().nullable(),
})

export type ForecastValueDto = z.infer<typeof ForecastValueDto>

export const ForecastDto = z.object({
  tagName: z.string(),
  madeAt: z.string().datetime(),
  prediction: z.array(ForecastValueDto),
})

export type ForecastDto = z.infer<typeof ForecastDto>
