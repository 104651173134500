import { parseISO } from 'date-fns'
import {
  GqlAnomalyCommentFragment,
  GqlFetchAnomalyFragment,
  GqlNavigationAnomalyFragment,
} from '../graphqlTypes'
import { Anomaly, AnomalyComment, NavigationAnomaly } from './anomaly.types'

export function mapApiToAnomaly(anomaly: GqlFetchAnomalyFragment): Anomaly {
  return {
    ...anomaly,
    start: parseISO(anomaly.start),
    end: anomaly.end ? parseISO(anomaly.end) : null,
    previous: anomaly.previous
      ? mapApiToNavigationAnomaly(anomaly.previous)
      : undefined,
    next: anomaly.next ? mapApiToNavigationAnomaly(anomaly.next) : undefined,
    comments: anomaly.comments.map(mapApiToAnomalyComment),
  }
}

function mapApiToNavigationAnomaly(
  anomaly: GqlNavigationAnomalyFragment,
): NavigationAnomaly {
  return {
    ...anomaly,
    start: parseISO(anomaly.start),
    end: anomaly.end ? parseISO(anomaly.end) : null,
  }
}

function mapApiToAnomalyComment(
  comment: GqlAnomalyCommentFragment,
): AnomalyComment {
  return {
    ...comment,
    at: parseISO(comment.at),
  }
}
